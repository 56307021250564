import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { sentenceCap } from "../../utils/utils";
import { Context } from "../Cache";
import Button from "../UI/Button";
import SelectOptions from "../UI/SelectOptions";

const getFormattedName = (member, short) => {
  if (short) {
    return `${member.firstName} ${member.lastName.charAt(0)}`;
  }
  return `${member.firstName} ${member.lastName}`;
};

const IncidentFilter = (props) => {
  const [state, dispatch] = useContext(Context);
  const [filterKey, setfilterKey] = useState(props.apiParams.filterKey);
  const [filterValue, setfilterValue] = useState(props.apiParams.filterValue);
  const [sort, setSort] = useState(props.apiParams.sort || "newest-first");

  const defaultStartTime = new Date();
  defaultStartTime.setHours(0, 0, 0, 0);
  defaultStartTime.setDate(defaultStartTime.getDate() - 7);

  const defaultEndTime = new Date();
  defaultEndTime.setHours(23, 59, 59, 999);

  const [startDate, setStartDate] = useState(
    props.apiParams.startDate
      ? parseInt(props.apiParams.startDate)
      : defaultStartTime.getTime()
  );
  const [endDate, setEndDate] = useState(
    props.apiParams.endDate
      ? parseInt(props.apiParams.endDate)
      : defaultEndTime.getTime()
  );

  const renderOptions = () => {
    console.log("available fields: ", availableFields);
    const field = availableFields.find((a) => a.id === filterKey);
    if (!field) {
      console.log("could not find filed with key: ", filterKey);
      return null;
    }
    console.log(field);

    switch (field.type) {
      case "dropdown":
        return (
          <SelectOptions
            value={filterValue}
            padding={false}
            options={field.options}
            optionLabels={field.options.map((option) => sentenceCap(option))}
            handleChange={(val) => {
              setfilterValue(val);
            }}
          />
        );
      case "memberResponding":
        return (
          <SelectOptions
            value={filterValue}
            options={state.memberList?.map((member) => {
              return member.id;
            })}
            optionLabels={state.memberList?.map((member) => {
              return getFormattedName(member);
            })}
            handleChange={(val) => {
              setfilterValue(val);
            }}
          />
        );
      default:
        return null;
    }
  };

  const whitelist = ["dropdown"];
  const availableFields = props.incidentData.keys
    .map((fieldKey) => {
      const field = props.incidentData.getField(fieldKey);
      if (whitelist.includes(field.type)) {
        return field;
      }
      return null;
    })
    .filter((field) => field);
  availableFields.push({
    key: "status",
    id: "status",
    label: "Status",
    type: "dropdown",
    options: ["open", "closed"],
  });
  if (state.memberList) {
    availableFields.push({
      key: "memberResponding",
      id: "memberResponding",
      label: "Member present",
      type: "memberResponding",
    });
  }
  return (
    <div className="flex flex-row flex-wrap items-end px-2 pb-4">
      <label className="w-full md:w-auto">
        <span className="text-gray-700 mb-1">From</span>
        <div className="w-full">
          <DatePicker
            className="form-input mt-1 block w-full"
            selected={startDate ? new Date(startDate) : null}
            onChange={(date) => {
              if (!date) {
                setStartDate("");
                return;
              }
              date.setHours(0, 0, 0, 0);
              setStartDate(date.getTime());
            }}
            dateFormat="MMMM d, yyyy"
          />
        </div>
      </label>

      <label className="w-full md:w-auto md:ml-2">
        <span className="text-gray-700 mb-1">To</span>
        <div className="w-full">
          <DatePicker
            className="form-input mt-1 block w-full"
            selected={endDate ? new Date(endDate) : null}
            onChange={(date) => {
              if (!date) {
                setEndDate("");
                return;
              }
              date.setHours(23, 59, 59, 999);
              setEndDate(date.getTime());
            }}
            dateFormat="MMMM d, yyyy"
          />
        </div>
      </label>

      <SelectOptions
        padding={false}
        label="Filter"
        className="w-full md:w-auto md:ml-2"
        unselectedLabel="No filter"
        value={filterKey}
        options={availableFields.map((field) => field.id)}
        optionLabels={availableFields.map((field) => field.label)}
        handleChange={(val) => {
          setfilterValue("");
          console.log("selected: " + val);
          setfilterKey(val);
        }}
      />
      {filterKey ? (
        <div className="w-full md:w-auto md:ml-2">{renderOptions()}</div>
      ) : null}

      <SelectOptions
        padding={false}
        label="Sort"
        className="w-full md:w-auto md:ml-2"
        value={sort}
        options={["oldest-first", "newest-first"]}
        handleChange={(val) => {
          setSort(val);
        }}
      />

      <Button
        title="Submit"
        loading={props.loading}
        className="w-full md:w-auto md:ml-2"
        onClick={() => {
          props.onSet({ filterKey, filterValue, sort, startDate, endDate });
        }}
      />
    </div>
  );
};

export default IncidentFilter;
