import React, { useState, useEffect, useContext } from "react";
import cx from "classnames";
import api from "../../utils/api";
import ScaleLoader from "react-spinners/ScaleLoader";
import { SPINNER_COLOR } from "../../utils/utils";
import { format } from "date-fns";
import { Context } from "../Cache";
import { usePrimaryColor, useFetchMemberName } from "../../utils/utils";

const AddComponent = (props) => {
  const primaryColor = usePrimaryColor();
  const fetchMemberName = useFetchMemberName();
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const result = await api.get(
          `/historyList?id=${props.match.params.id}`
        );
        setHistoryList(result.data.historyList);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    if (props.match.params.id) {
      fetchData();
    }
  }, [props.match.params.id]);

  if (!state.config) {
    return;
  }

  const renderChangeIcon = (change) => {
    switch (change.action) {
      case "created":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-2"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-2"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
          </svg>
        );
    }
  };

  const renderArrow = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 mx-1"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="5" y1="12" x2="19" y2="12"></line>
        <polyline points="12 5 19 12 12 19"></polyline>
      </svg>
    );
  };

  const renderFieldName = (fieldAffected) => {
    const field = state.config.form.fieldsMap[fieldAffected];
    if (!field) {
      return "Unkown field";
    } else {
      return field.label;
    }
  };

  const renderChanges = (changes) => {
    return (
      <div className="text-sm w-full">
        {changes.map((change) => {
          return (
            <div
              key={change.changedAt}
              className="mb-1 flex flex-row flex-wrap justify-between w-full mb-2"
            >
              <div className="flex flex-row items-center w-full">
                <div className={`flex-shrink-0 text-${primaryColor}-300`}>
                  {renderChangeIcon(change)}
                </div>
                {change.fieldAffected ? (
                  <div>{renderFieldName(change.fieldAffected)}</div>
                ) : null}
                {change.action === "created" ? (
                  <div>Incident reported</div>
                ) : null}
              </div>
              <div className="w-full text-gray-600">
                {change.previousValue || change.updatedValue ? (
                  <div className="flex flex-row items-center justify-between">
                    <div className="w-full">{change.previousValue}</div>
                    <div className="flex-shrink-0 mx-2">{renderArrow()}</div>
                    <div className="w-full">{change.updatedValue}</div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderHistoryList = () => {
    return (
      <>
        <div className="font-semibold tracking-wide uppercase pb-4">
          History
        </div>
        <div>
          {historyList.map((historyItem, index) => {
            return (
              <>
                <div className="flex flex-row flex-wrap text-sm">
                  <div
                    className={`w-1/2 text-${primaryColor}-800 font-semibold uppercase text-xs tracking-wide`}
                  >
                    {fetchMemberName(historyItem.memberId)}
                  </div>
                  <div
                    className={`w-1/2 text-gray-500 font-semibold uppercase text-xs tracking-wide text-right`}
                  >
                    {format(new Date(historyItem.changedAt), "dd/MM/yy h:mma")}
                  </div>
                  {renderChanges(historyItem.changes)}
                </div>
                {index !== historyList.length - 1 ? (
                  <hr className="my-2" />
                ) : null}
              </>
            );
          })}
        </div>
      </>
    );
  };

  if (!historyList || historyList.length === 0) {
    return null;
  }
  return (
    <div className="max-w-xl w-full bg-white px-8 py-4 rounded shadow-md m-4">
      {loading ? (
        <div className="w-full flex justify-center items-center">
          <ScaleLoader size={150} color={SPINNER_COLOR} loading={true} />
        </div>
      ) : (
        renderHistoryList()
      )}
    </div>
  );
};

export default AddComponent;
