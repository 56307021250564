import React, { useEffect, useState } from "react";
import { usePrimaryColor } from "../../utils/utils";
import TextInput from "../UI/TextInput";
import IconButton from "../UI/IconButton";
const cx = require("classnames");

export default (props) => {
  const primary = usePrimaryColor();
  const [tempTitle, setTempTitle] = useState(props.title);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setTempTitle(props.title);
  }, [props.title]);

  return (
    <div
      className={cx("flex flex-row items-center cursor-pointer")}
      onClick={props.onClick}
    >
      {isEditing ? (
        <TextInput
          noSpacing
          className="flex-grow w-64 mr-2"
          value={tempTitle}
          onChangeText={(val) => {
            setTempTitle(val);
          }}
        />
      ) : (
        <div className="flex-grow whitespace-no-wrap">{props.title}</div>
      )}
      {isEditing ? (
        <>
          {/* Save button */}
          <IconButton
            color="text-green-500"
            onClick={() => {
              if (tempTitle !== props.title) {
                props.onTextUpdate(tempTitle);
              }
              setIsEditing(false);
            }}
            icon={
              <>
                <polyline points="20 6 9 17 4 12"></polyline>
              </>
            }
          />
          {/* Cancel button */}
          <IconButton
            color="text-gray-500"
            onClick={() => {
              setIsEditing(false);
            }}
            icon={
              <>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </>
            }
          />
        </>
      ) : (
        <>
          {/* Edit button */}
          <IconButton
            color="text-gray-500"
            onClick={() => {
              setIsEditing(true);
            }}
            icon={
              <>
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
              </>
            }
          />
          {props.hideMovement ? null : (
            <>
              {/* Move up button */}
              <IconButton
                color="text-gray-500"
                onClick={props.onUpClick}
                icon={
                  <>
                    <line x1="12" y1="19" x2="12" y2="5"></line>
                    <polyline points="5 12 12 5 19 12"></polyline>
                  </>
                }
              />
              {/* Move down button */}
              <IconButton
                color="text-gray-500"
                onClick={props.onDownClick}
                icon={
                  <>
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <polyline points="19 12 12 19 5 12"></polyline>
                  </>
                }
              />
            </>
          )}
          {/* Delete button */}
          <IconButton
            color="text-red-500"
            onClick={props.onDeleteClick}
            icon={
              <>
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </>
            }
          />
        </>
      )}
    </div>
  );
};
