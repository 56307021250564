import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import Button from "../UI/Button";

class NotWhitelisted extends Component {
  render() {
    return (
      <div className="flex flex-col justify-center items-center p-8">
        <div className="max-w-md text-center mb-8">
          Your phone number has not been whitelisted to use this app.{"\n\n"}
          Please contact your management team.
        </div>
        <Button
          title={"Sign out"}
          onClick={async () => {
            await this.props.firebase.doSignOut();
            this.props.history.push(ROUTES.SIGN_IN);
          }}
        />
      </div>
    );
  }
}
export default withRouter(withFirebase(NotWhitelisted));
