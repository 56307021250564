import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import api from "../../utils/api";
import {
  processError,
  SPINNER_COLOR,
  useFetchMemberName,
  usePrimaryColor,
} from "../../utils/utils";
import { Context } from "../Cache";
import Button from "../UI/Button";
import SelectOptions from "../UI/SelectOptions";
import TextArea from "../UI/TextArea";

const AddComponent = (props) => {
  const primaryColor = usePrimaryColor();
  const fetchMemberName = useFetchMemberName();
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [status, setStatus] = useState("");
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await api.get(`/commentsList?id=${props.match.params.id}`);
      setComments(result.data.comments);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setStatus(props.incident.status);

    if (props.match.params.id) {
      fetchData();
    }
  }, [props.match.params.id]);

  if (!state.config) {
    return;
  }

  const renderComments = () => {
    if (!comments || comments.length === 0) {
      return null;
    }
    return (
      <div>
        <hr className="my-4" />
        {comments.map((historyItem, index) => {
          if (!historyItem.text) {
            return null;
          }
          return (
            <>
              <div className="flex flex-row flex-wrap text-sm">
                <div
                  className={`w-1/2 text-${primaryColor}-800 font-semibold uppercase text-xs tracking-wide`}
                >
                  {fetchMemberName(historyItem.memberId)}
                </div>
                <div
                  className={`w-1/2 text-gray-500 font-semibold uppercase text-xs tracking-wide text-right`}
                >
                  {format(new Date(historyItem.postedAt), "dd/MM/yy h:mma")}
                </div>
                <div className="w-full text-gray-600">{historyItem.text}</div>
              </div>
              {index !== comments.length - 1 ? <hr className="my-2" /> : null}
            </>
          );
        })}
      </div>
    );
  };

  if (!props.incident.canComment && (!comments || comments.length === 0)) {
    return null;
  }
  return (
    <div className="max-w-xl w-full bg-white px-8 py-4 rounded shadow-md m-4">
      {loading ? (
        <div className="w-full flex justify-center items-center">
          <ScaleLoader size={150} color={SPINNER_COLOR} loading={true} />
        </div>
      ) : (
        <div>
          <div className="font-semibold tracking-wide uppercase">
            Management
          </div>
          {props.incident.canComment ? (
            <div className="mt-4">
              <TextArea
                label="Send a message"
                value={newComment}
                onChangeText={(text) => {
                  setNewComment(text);
                }}
              />
              <div className="flex flex-row justify-between items-center">
                <SelectOptions
                  label="Status"
                  value={status}
                  handleChange={(val) => {
                    setStatus(val);
                  }}
                  options={
                    state.config.incident?.statusOptions || ["Open", "Closed"]
                  }
                />
                <Button
                  title="Submit"
                  loading={loadingSubmit}
                  flat
                  onClick={async () => {
                    try {
                      setLoadingSubmit(true);
                      await api.post("/commentsAdd", {
                        incidentId: props.match.params.id,
                        status,
                        text: newComment,
                      });
                      setNewComment("");
                      fetchData();
                    } catch (e) {
                      console.log("error: ", e);
                      alert(processError(e, "Error adding comment."));
                    } finally {
                      setLoadingSubmit(false);
                    }
                  }}
                />
              </div>
            </div>
          ) : null}

          {renderComments()}
        </div>
      )}
    </div>
  );
};

export default AddComponent;
