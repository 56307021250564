import React from "react";
import Transition from "../UI/Transition";
import Button from "../UI/Button";
import { renderFile } from "./FileDisplayAndUpload";

const FileViewer = (props) => {
  if (!props.viewingFile) {
    return null;
  }
  return (
    <Transition
      show={props.viewingFile !== null}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed bottom-0 inset-0 p-0 flex items-center justify-center z-50">
        <div className="fixed inset-0 transition-opacity">
          <div
            className="absolute inset-0 bg-gray-900"
            style={{ opacity: 0.95 }}
          ></div>
        </div>
        <Transition
          show={props.viewingFile != null}
          enter="transition ease-out duration-300"
          enterFrom="translate-y-0 scale-95"
          enterTo="opacity-100 translate-y-0 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 scale-100"
          leaveTo="opacity-0 translate-y-0 scale-95"
        >
          <div className="relative rounded-lg overflow-auto shadow-xl mx-8 transform transition-all flex flex-col max-w-2xl w-full max-h-screen overflow-auto h-11/12">
            {props.showDelete ? (
              <div
                className="block absolute top-0 right-0"
                onClick={() => {
                  props.delete();
                }}
              >
                <button
                  type="button"
                  className="text-white hover:text-gray-200 focus:outline-none focus:text-gray-200 transition ease-in-out duration-150"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-10 w-10 p-2 rounded-full"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </button>
              </div>
            ) : null}
            <div className="flex-grow overflow-hidden">
              {props.viewingFile !== null
                ? renderFile(
                    props.viewingFile,
                    "w-full h-full max-w-full max-h-full",
                    true
                  )
                : null}
            </div>
            <Button
              className="flex-shrink-0 my-2"
              title={"close"}
              full
              secondary
              onClick={() => {
                props.close();
              }}
            />
          </div>
        </Transition>
      </div>
    </Transition>
  );
};
export default FileViewer;
