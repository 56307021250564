const local = {
  base_url: "http://localhost:4390",
  env: "development",
  firebase: {
    apiKey: "AIzaSyDwyczP2V99KZxD6iRu7ZqizGKocvOn4Xk",
    authDomain: "ereport-staging-d5b98.firebaseapp.com",
    databaseURL: "https://ereport-staging-d5b98.firebaseio.com",
    projectId: "ereport-staging-d5b98",
    storageBucket: "ereport-staging-d5b98.appspot.com",
    messagingSenderId: "462092440987",
    appId: "1:462092440987:web:81437605c4f203540abd3e",
  },
};

const staging = {
  base_url: "https://ereport-backend-staging.herokuapp.com",
  env: "staging",
  firebase: {
    apiKey: "AIzaSyDwyczP2V99KZxD6iRu7ZqizGKocvOn4Xk",
    authDomain: "ereport-staging-d5b98.firebaseapp.com",
    databaseURL: "https://ereport-staging-d5b98.firebaseio.com",
    projectId: "ereport-staging-d5b98",
    storageBucket: "ereport-staging-d5b98.appspot.com",
    messagingSenderId: "462092440987",
    appId: "1:462092440987:web:81437605c4f203540abd3e",
  },
};

const production = {
  base_url: "https://ereport-backend.herokuapp.com",
  env: "production",
  firebase: {
    apiKey: "AIzaSyDZwHFM64ZmAEPfwThYiPBiG6IOMkaHxo0",
    authDomain: "ereport-production-15d64.firebaseapp.com",
    databaseURL: "https://ereport-production-15d64.firebaseio.com",
    projectId: "ereport-production-15d64",
    storageBucket: "ereport-production-15d64.appspot.com",
    messagingSenderId: "598434835476",
    appId: "1:598434835476:web:d0718a9da5cb6c1e374b01",
  },
};

let exportConfig = production;
const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
console.log("environment: ", env);
console.log("development: ", env === "development");
if (env === "development") {
  exportConfig = local;
} else if (env === "staging") {
  exportConfig = staging;
}

export default exportConfig;
