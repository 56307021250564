import React, { Component, useContext } from "react";
import { withRouter } from "react-router-dom";
import { withAuthentication } from "../Session";
import * as ROUTES from "../../constants/routes";
import firebase from "firebase/app";
import Button from "../UI/Button";
import { setAuthToken } from "../../utils/api";
import { Context } from "../Cache";
import config from "../../utils/config";
import PhoneNumberInput from "../UI/PhoneNumberInput";

const SignInPage = () => {
  const [state, dispatch] = useContext(Context);

  return (
    <div className="flex flex-col p-16 bg-white items-center">
      {state.config ? (
        <>
          {state.config.appearance.logo ? (
            <img
              className="h-auto object-contain w-48 border-none"
              src={state.config.appearance.logo}
            />
          ) : null}
          <div className="text-gray-900 text-lg font-bold text-center mt-6 mb-8">
            {state.config.appearance.name}
          </div>
        </>
      ) : null}
      <SignInForm />
    </div>
  );
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      phoneNumber: "",
      verificationCode: "",
      error: null,
      showEnterCode: false,
      confirmationResult: null,
    };
  }

  componentDidMount() {
    console.log(this.props.user);
    if (this.props.user && this.props.user.whitelisted) {
      this.props.history.push(ROUTES.INCIDENTS_LIST);
    }
  }

  onEnterVerificationCode = () => {
    this.setState({
      loading: true,
      error: null,
      confirmationResult: null,
    });

    this.state.confirmationResult
      .confirm(this.state.verificationCode)
      .then((data) => {
        console.log("user: ", data.user);
        return data.user.getIdToken();
      })
      .then((token) => {
        setAuthToken(token);
        this.setState({ loading: false, showEnterCode: false });
        this.props.history.push(ROUTES.INCIDENTS_LIST);
      })
      .catch((error) => {
        this.setState({ error, loading: false, showEnterCode: false });
      });
  };

  onEnterPhoneNumber = () => {
    this.setState({
      loading: true,
      error: null,
      confirmationResult: null,
    });

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
    }

    let { phoneNumber } = this.state;
    console.log("signing in: ", phoneNumber);
    this.props.firebase
      .doSignInWithPhone(phoneNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        this.setState({
          confirmationResult,
          loading: false,
          showEnterCode: true,
        });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { phoneNumber, verificationCode, error } = this.state;
    return (
      <>
        {this.state.showEnterCode ? (
          <div className="flex flex-col w-full max-w-md">
            <div className="text-gray-900 text-sm mb-4">
              Enter the SMS verification code. If you do not receive one, please
              wait 30 seconds and then refresh the page to request a new code.
            </div>
            <label className="block mb-4">
              <div className="text-gray-700">Verification code:</div>
              <input
                name="verificationCode"
                className="form-input mt-1 block w-full"
                type="tel"
                value={verificationCode}
                onChange={this.onChange}
              />
            </label>
            <Button
              onClick={() => {
                this.onEnterVerificationCode();
              }}
              loading={this.state.loading}
              title={"submit"}
            />
          </div>
        ) : (
          <div className="flex flex-col w-full max-w-md">
            <PhoneNumberInput
              className="mb-4"
              defaultValue={"+254"}
              onPhoneNumberUpdated={(phoneNumber) => {
                this.setState({ phoneNumber });
              }}
            />
            <Button
              onClick={() => {
                this.onEnterPhoneNumber();
              }}
              loading={this.state.loading}
              title={"submit"}
            />
          </div>
        )}

        {error && <p>{error.message}</p>}
        <div id="recaptcha-container" />
      </>
    );
  }
}

const SignInForm = withRouter(withAuthentication(SignInFormBase));

export default SignInPage;
export { SignInForm };
