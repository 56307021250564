import React, { useState, useRef } from "react";
import { CSVLink } from "react-csv";
import Button from "../UI/Button";
import { useProcessIncidentsData, extraTitles } from "./IncidentDataProcess";
import api from "../../utils/api";
import { format } from "date-fns";

var qs = require("qs");

export default (props) => {
  const processIncidentsData = useProcessIncidentsData();
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const csvLink = useRef(null);
  const incidentData = processIncidentsData();

  const loadData = () => {
    setLoading(true);
    try {
      const updatedIncidents = (props.incidents || []).map((incident) => {
        const tempIncident = {};
        tempIncident["#"] = incident.incidentNumber;
        tempIncident.Status = incident.status;
        tempIncident["Reported At"] = format(
          new Date(incident.reportedAt),
          "MMM dd, yyyy h:mma"
        );
        const keys = incidentData.keys;
        console.log("incidentData", incidentData);
        for (var i = 0; i < keys.length; i++) {
          const key = keys[i];
          const title = incidentData.titles[i + extraTitles.length];
          tempIncident[title] = incidentData.getDisplayValue(incident, key);
        }
        return tempIncident;
      });
      console.log("updatedIncidents: ", updatedIncidents);
      setExportData(updatedIncidents);
      setTimeout(() => {
        csvLink.current.link.click();
      }, 200);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        className="w-full md:w-auto"
        title="Export results to CSV"
        flat
        onClick={() => {
          loadData();
        }}
        loading={loading}
      />

      <CSVLink
        data={exportData}
        filename="data.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};
