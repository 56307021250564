import React, { useContext, useEffect, useState } from "react";
import api from "../../utils/api";
import { processError } from "../../utils/utils";
import { Context } from "../Cache";
import Button from "../UI/Button";
import Checkbox from "../UI/Checkbox";
import PermissionsSelector from "../UI/PermissionsSelector";
import PhoneNumberInput from "../UI/PhoneNumberInput";
import SelectOptions from "../UI/SelectOptions";
import TextInput from "../UI/TextInput";
import Transition from "../UI/Transition";
const cx = require("classnames");

const defaultFormState = () => {
  return {
    permissions: {},
    enabled: true,
  };
};

const AddMemberForm = (props) => {
  const [state, dispatch] = useContext(Context);
  const [formData, setFormData] = useState(defaultFormState());
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const cities = Object.keys(state.config.form?.fields || {})
    .map((key) => {
      return state.config.form.fields[key];
    })
    .flat()
    .filter((field) => field.id === "city")
    .map((field) => field.options)
    .flat();
  useEffect(() => {
    console.log("use effect called");
    if (!props.editMember || !props.editMember.id) {
      setFormData(defaultFormState());
      return;
    }
    setFormData({ ...defaultFormState(), ...props.editMember });
  }, [props.editMember]);

  const submitForm = async () => {
    try {
      setLoadingSubmit(true);
      const data = formData;
      await api.post("/admin/memberAdd", {
        ...data,
      });
      props.memberAdded();
      setFormData(defaultFormState());
    } catch (e) {
      console.log("error: ", e);
      alert(processError(e, "Error adding member."));
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <Transition
      show={props.show}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed bottom-0 inset-0 p-0 flex items-center justify-center z-50">
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => {
            props.hide();
          }}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
        </div>
        <Transition
          show={props.show}
          enter="transition ease-out duration-300"
          enterFrom="translate-y-0 scale-95"
          enterTo="opacity-100 translate-y-0 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 scale-100"
          leaveTo="opacity-0 translate-y-0 scale-95"
        >
          <div className="bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all max-w-2xl w-full max-h-screen overflow-auto sm:p-6">
            <div className="flex flex-row justify-between items-center mb-2">
              <TextInput
                label={"First name"}
                className="w-1/2 flex-shrink"
                value={formData.firstName}
                onChangeText={(text) => {
                  const obj = { ...formData };
                  obj.firstName = text;
                  setFormData(obj);
                }}
              />
              <div className="w-8 flex-shrink-0">&nbsp;</div>
              <TextInput
                label={"Last name"}
                className="w-1/2 flex-shrink"
                value={formData.lastName}
                onChangeText={(text) => {
                  const obj = { ...formData };
                  obj.lastName = text;
                  setFormData(obj);
                }}
              />
            </div>
            <PhoneNumberInput
              className="mb-4"
              defaultValue={formData.phoneNumber}
              onPhoneNumberUpdated={(val) => {
                const obj = { ...formData };
                obj.phoneNumber = val;
                setFormData(obj);
              }}
            />

            <div className="flex flex-row justify-between items-center mb-2">
              <TextInput
                className="w-1/2 flex-shrink"
                label={"Email address"}
                type="email"
                value={formData.email}
                onChangeText={(text) => {
                  const obj = { ...formData };
                  obj.email = text;
                  setFormData(obj);
                }}
              />
              <div className="w-8 flex-shrink-0">&nbsp;</div>
              <SelectOptions
                className="w-1/2 flex-shrink"
                label={"Notifications"}
                value={formData.notifications}
                handleChange={(value) => {
                  const obj = { ...formData };
                  obj.notifications = value;
                  setFormData(obj);
                }}
                options={["none", "sms", "email", "whatsapp"]}
                optionLabels={["none", "SMS", "email", "WhatsApp"]}
              />
            </div>
            <div className="flex flex-row justify-between items-center mb-2">
              {cities ? (
                <SelectOptions
                  className="w-1/2 flex-shrink"
                  label={"City"}
                  value={formData.city}
                  handleChange={(value) => {
                    const obj = { ...formData };
                    obj.city = value;
                    setFormData(obj);
                  }}
                  options={cities}
                />
              ) : null}
              <div className="w-8 flex-shrink-0">&nbsp;</div>
              <TextInput
                label={"Call sign"}
                className="w-1/2 flex-shrink"
                value={formData.callSign}
                onChangeText={(text) => {
                  const obj = { ...formData };
                  obj.callSign = text;
                  setFormData(obj);
                }}
              />
            </div>
            <PermissionsSelector
              className="w-full"
              permissions={formData.permissions || {}}
              user={props.editMember}
              updatePermissions={(permissionKey) => {
                const obj = { ...formData };
                obj.permissions.read = permissionKey;
                setFormData(obj);
              }}
              type="read"
            />
            <PermissionsSelector
              className="w-full"
              permissions={formData.permissions || {}}
              user={props.editMember}
              updatePermissions={(permissionKey) => {
                const obj = { ...formData };
                obj.permissions.comment = permissionKey;
                setFormData(obj);
              }}
              type="comment"
            />
            <PermissionsSelector
              className="w-full"
              permissions={formData.permissions || {}}
              user={props.editMember}
              updatePermissions={(permissionKey) => {
                const obj = { ...formData };
                obj.permissions.edit = permissionKey;
                setFormData(obj);
              }}
              type="edit"
            />
            <div className="flex flex-row justify-between mb-2">
              <Checkbox
                label={"Whitelisted"}
                checked={formData.enabled}
                onChange={(val) => {
                  console.log("changing to:  ", val);
                  const obj = { ...formData };
                  obj.enabled = val;
                  setFormData(obj);
                }}
              />
              <Checkbox
                label={"Hide in member list"}
                checked={formData.guest}
                onChange={(val) => {
                  const obj = { ...formData };
                  obj.guest = val;
                  setFormData(obj);
                }}
              />
              <Checkbox
                label={"Admin dashboard access"}
                checked={formData.admin}
                onChange={(val) => {
                  const obj = { ...formData };
                  obj.admin = val;
                  setFormData(obj);
                }}
              />
            </div>
            <Button
              className="mt-8"
              full
              loading={loadingSubmit}
              onClick={() => (loadingSubmit ? null : submitForm())}
              title={"Submit"}
            />
          </div>
        </Transition>
      </div>
    </Transition>
  );
};
export default AddMemberForm;
