import React from "react";
import cx from "classnames";

const Badge = ({ text, className, bgColor, fgColor }) => {
  return (
    <div
      className={cx(
        "px-2 py-1 font-semibold uppercase rounded-full text-xs text-center",
        bgColor,
        fgColor,
        className
      )}
    >
      {text}
    </div>
  );
};

const StatusBadge = ({ incident, className }) => {
  return (
    <Badge
      text={incident.status}
      className={className}
      bgColor={incident.status === "open" ? "bg-green-200" : "bg-gray-200"}
      fgColor={incident.status === "open" ? "text-green-800" : "text-gray-800"}
    />
  );
};
export { Badge, StatusBadge };
