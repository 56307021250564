import React, { Component } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { getPermissionsText } from "../../utils/permissions";
import { SPINNER_COLOR } from "../../utils/utils";
import { Context } from "../Cache";
import Button from "../UI/Button";
import TextInput from "../UI/TextInput";
const cx = require("classnames");

class MembersTable extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      currentMembers: [],
    };
  }

  renderMemberList(members) {
    const [state, dispatch] = this.context;
    if (!state.memberList) {
      return null;
    }
    return state.memberList
      .filter((a) => {
        if (!this.props.admin && (a.guest || !a.enabled)) {
          //always hide guests if not admin
          return false;
        }

        if (!this.state.search) {
          return true;
        } else {
          const match = this.state.search.replace(/\s/g, "").toLowerCase();
          return [
            a.firstName.toLowerCase(),
            a.lastName.toLowerCase(),
            a.phoneNumber,
          ]
            .join("")
            .includes(match);
        }
      })
      .sort((a, b) => {
        const nameA = `${a.firstName}${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName}${b.lastName}`.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      })
      .map((data) => {
        return (
          <tr key={data.id} className={cx({ "opacity-25": !data.enabled })}>
            <td className="pl-2 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
              {data.firstName} {data.lastName}
            </td>
            <td className="whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
              <a
                className="text-primary-500 underline px-2 py-4 font-mono block mr-1"
                href={`tel:${data.phoneNumber}`}
              >
                {data.phoneNumber}
              </a>
            </td>
            <td className="py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
              {data.callSign}
            </td>
            {this.props.admin ? (
              <>
                <td className="py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {data.email}
                </td>
                <td className="py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {data.city}
                </td>
                <td className="py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  Read: {getPermissionsText(state, data.permissions, "read")}
                  <br />
                  Edit: {getPermissionsText(state, data.permissions, "edit")}
                  <br />
                  Comment:{" "}
                  {getPermissionsText(state, data.permissions, "comment")}
                </td>
                <td className="whitespace-no-wrap border-b border-gray-200 text-sm leading-5 underline">
                  <div
                    className={cx("py-4 cursor-pointer", {
                      "cursor-wait": this.props.loadingId === data.id,
                    })}
                    onClick={() => {
                      if (this.props.loadingId === data.id) {
                        return;
                      }
                      this.props.openEditModal(data);
                    }}
                  >
                    Edit
                  </div>
                </td>
              </>
            ) : null}
          </tr>
        );
      });
  }

  renderSearchBar() {
    const [state, dispatch] = this.context;
    return (
      <>
        {this.props.admin ? (
          <div>
            Whitelisted users:{" "}
            {state.memberList.filter((a) => a.enabled).length}
          </div>
        ) : null}
        <div className="w-full mb-2 flex flex-row">
          <div className="w-full">
            <TextInput
              noSpacing={true}
              placeholder="Search..."
              value={this.state.search}
              onChangeText={(text) => this.setState({ search: text })}
            />
          </div>
          {this.props.admin ? (
            <Button
              className="flex-shrink-0 ml-4"
              title="Add member"
              onClick={() => {
                this.props.showAddMemberForm();
              }}
            />
          ) : null}
        </div>
      </>
    );
  }

  render() {
    const [state, dispatch] = this.context;
    return (
      <div
        className={cx(
          "flex flex-col px-0 pt-4 items-center bg-off-white",
          this.props.className
        )}
      >
        {state.memberList === null ? (
          <div className="mt-8">
            <ScaleLoader size={150} color={SPINNER_COLOR} loading={true} />
          </div>
        ) : (
          <>
            {this.renderSearchBar()}
            <div
              className={cx(
                "align-middle inline-block w-full shadow overflow-scroll sm:rounded-lg border-b border-gray-300",
                { "overflow-hidden": !this.props.admin },
                { "overflow-scroll": this.props.admin }
              )}
            >
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="px-2 py-2 border-b border-gray-200 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-2 py-2 border-b border-gray-200 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Phone number
                    </th>
                    <th className="px-2 py-2 border-b border-gray-200 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Call sign
                    </th>
                    {this.props.admin ? (
                      <>
                        <th className="px-2 py-2 border-b border-gray-200 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Email
                        </th>
                        <th className="px-2 py-2 border-b border-gray-200 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          City
                        </th>
                        <th className="px-2 py-2 border-b border-gray-200 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Permissions
                        </th>
                        <th className="px-2 py-2 border-b border-gray-200 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Options
                        </th>
                      </>
                    ) : null}
                  </tr>
                </thead>
                <tbody className="bg-white">{this.renderMemberList()}</tbody>
              </table>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default MembersTable;
