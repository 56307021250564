import cx from "classnames";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import api from "../../utils/api";
import { SPINNER_COLOR, usePrimaryColor } from "../../utils/utils";
import { Context } from "../Cache";
import * as ACTIONS from "../Cache/actions";
import Button from "../UI/Button";
import IconButton from "../UI/IconButton";
import OnDutyForm from "./OnDutyForm";
import withAdminWrapper from "./withAdminWrapper";

const OnDuty = (props) => {
  const [state, dispatch] = useContext(Context);
  const primaryColor = usePrimaryColor();
  const [loading, setLoading] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [itemLoading, setItemLoading] = useState(null);

  const loadOnDuty = async () => {
    setLoading(true);
    try {
      const result = await api.get("/widgets/onDuty");
      console.log(result);
      dispatch({
        type: ACTIONS.ACTION_ON_DUTY_SET,
        payload: result.data.onDutyItems,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteItem = async (id) => {
    try {
      setItemLoading(id);
      await api.post("/admin/onDutyDelete", {
        id,
      });
      loadOnDuty();
    } catch (e) {
      console.log(e);
    } finally {
      setItemLoading(null);
    }
  };

  useEffect(() => {
    loadOnDuty();
  }, []);

  const renderWidgetValue = (onDutyItem) => {
    return (
      <>
        <div className="uppercase text-xs font-semibold tracking-wide">
          {onDutyItem.title}
        </div>
        {onDutyItem.members
          ? onDutyItem.members.map((memberId) => {
              const member = state.memberList.find(
                (member) => member.id === memberId
              );
              return (
                <div
                  key={memberId}
                  className="flex flex-row items-center justify-between text-sm"
                >
                  <span className="block">
                    <span className="inline-block mr-2">
                      {`${member.firstName} ${member.lastName}`}
                    </span>
                    {member.callSign ? (
                      <span className="inline-block">{` (${member.callSign})`}</span>
                    ) : null}
                  </span>
                  <a
                    className={cx(
                      "underline px-2 py-2 font-mono block mr-1",
                      `text-${primaryColor}-500`
                    )}
                    href={`tel:${member.phoneNumber}`}
                  >
                    {member.phoneNumber}
                  </a>
                </div>
              );
            })
          : null}
      </>
    );
  };

  return (
    <div className="flex flex-col px-8 py-4 items-center bg-off-white min-h-screen">
      {(loading && !state.onDutyItems) || !state.memberList ? (
        <div className="mt-8 mb-4">
          <ScaleLoader size={150} color={SPINNER_COLOR} loading={true} />
        </div>
      ) : (
        <>
          {state.onDutyItems
            ? state.onDutyItems
                .sort((a, b) => a.order - b.order)
                .map((onDutyItem) => {
                  return (
                    <div
                      className={cx(
                        "relative px-4 py-2 rounded overflow-hidden border-gray-300 border bg-white max-w-lg w-full mb-6",
                        {
                          "opacity-50 cursor-not-allowed":
                            !onDutyItem.editable ||
                            onDutyItem.id === itemLoading,
                        }
                      )}
                    >
                      {onDutyItem.editable ? (
                        <div className="absolute top-0 right-0 flex flex-row">
                          <IconButton
                            onClick={() => {
                              setEditItem(onDutyItem);
                            }}
                            color="text-gray-500"
                            icon={
                              <>
                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                              </>
                            }
                          />
                          <IconButton
                            onClick={() => {
                              deleteItem(onDutyItem.id);
                            }}
                            color="text-red-500"
                            icon={
                              <>
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </>
                            }
                          />
                        </div>
                      ) : null}
                      <div className="uppercase font-light text-center text-sm mb-2">
                        {`${format(new Date(onDutyItem.timeStart), "MMM dd ha")}
              - ${format(new Date(onDutyItem.timeEnd), "MMM dd ha")}
              `}
                      </div>
                      {renderWidgetValue(onDutyItem)}
                    </div>
                  );
                })
            : null}
        </>
      )}
      <Button
        className="w-full max-w-lg"
        title="Add item"
        onClick={() => {
          let order = 0;
          if (
            state.onDutyItems &&
            state.onDutyItems.length > 0 &&
            state.onDutyItems[state.onDutyItems.length - 1].order + 1
          ) {
            order = state.onDutyItems[state.onDutyItems.length - 1].order + 1;
          }
          setEditItem({
            order,
            editable: true,
          });
        }}
      />
      <OnDutyForm
        editItem={editItem}
        show={editItem != null}
        hide={() => {
          setEditItem(null);
        }}
        itemAdded={() => {
          setEditItem(null);
          loadOnDuty();
        }}
      />
    </div>
  );
};

export default withAdminWrapper(OnDuty, "On Duty");
