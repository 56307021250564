import React from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { getFormattedName } from "../../utils/utils";
import FileDisplayAndUpload from "../FileHandlers/FileDisplayAndUpload";
import SelectOptions from "../UI/SelectOptions";
import TextArea from "../UI/TextArea";
import TextInput from "../UI/TextInput";

const getOtherMembersSelected = (otherMembers, memberList) => {
  if (!otherMembers || otherMembers.length === 0 || memberList == null) {
    return;
  }
  const defaultValueSelected = [];

  for (var i = 0; i < otherMembers.length; i++) {
    const member = otherMembers[i];
    const foundMember = memberList.find((element) => element.id === member);
    if (foundMember) {
      defaultValueSelected.push({
        value: foundMember.id,
        label: getFormattedName(foundMember),
      });
    }
  }
  return defaultValueSelected;
};

const getFormComponent = ({
  field,
  formData,
  setFormData,
  user,
  memberList,
  fetchMemberName,
  firebase,
}) => {
  let label = field.label;
  const key = field.id;
  if (field.required) {
    label += "*";
  }

  switch (field.type) {
    case "memberResponding": {
      let name = fetchMemberName(user.id);
      if (formData[key]) {
        name = fetchMemberName(formData[key]);
      } else {
        setFormData({
          ...formData,
          [key]: user.id,
        });
      }
      return <TextInput disabled={true} label={label} value={name} />;
    }
    case "otherMembers": {
      return (
        <label className="block mb-4">
          <span className="text-gray-700 mb-1">{label}</span>
          <Select
            isMulti={true}
            isDisabled={memberList == null}
            value={getOtherMembersSelected(formData[key], memberList)}
            placeholder={memberList == null ? "Loading..." : ""}
            onChange={(data) => {
              const otherMembers = data
                ? data.map((dataItem) => dataItem.value)
                : [];
              setFormData({
                ...formData,
                [key]: otherMembers,
              });
            }}
            options={
              memberList
                ? memberList
                    .filter((item) => {
                      if (item.guest) {
                        return false;
                      }
                      if (!formData.city) {
                        return item;
                      }
                      console.log(
                        "checking city: " +
                          item.city +
                          " formData city: " +
                          formData.city
                      );
                      return (
                        item.city?.toLowerCase() ===
                        formData.city?.toLowerCase()
                      );
                    })
                    .map((member) => {
                      return {
                        value: member.id,
                        label: getFormattedName(member),
                      };
                    })
                : []
            }
          />
        </label>
      );
    }
    case "text":
      return (
        <TextInput
          label={label}
          value={formData[key]}
          onChangeText={(text) => {
            const obj = { ...formData };
            obj[key] = text;
            setFormData(obj);
          }}
        />
      );
    case "textArea":
      return (
        <TextArea
          label={label}
          value={formData[key]}
          multiline={true}
          numberOfLines={4}
          onChangeText={(text) => {
            const obj = { ...formData };
            obj[key] = text;
            setFormData(obj);
          }}
        />
      );
    case "dropdown":
    case "city":
    case "incidentType":
      return (
        <SelectOptions
          label={label}
          value={formData[key]}
          handleChange={(value) => {
            const obj = { ...formData };
            obj[key] = value;
            setFormData(obj);
          }}
          options={field.options}
        />
      );
    case "datetime":
      return (
        <label className="block mb-4">
          <span className="text-gray-700 mb-1">{label}</span>
          <div className="w-full">
            <DatePicker
              className="form-input mt-1 block w-full"
              selected={formData[key] ? new Date(formData[key]) : null}
              onChange={(date) => {
                const obj = { ...formData };
                obj[key] = date.toISOString();
                setFormData(obj);
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </div>
        </label>
      );
    case "files":
      return (
        <FileDisplayAndUpload
          firebase={firebase}
          files={formData[key] || []}
          onChange={(files) => {
            const obj = { ...formData };
            obj[key] = files;
            setFormData(obj);
          }}
        />
      );
    default:
      return null;
  }
};

export { getOtherMembersSelected, getFormComponent };
