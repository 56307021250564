import cx from "classnames";
import { format } from "date-fns";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as ROUTES from "../../constants/routes";
import api from "../../utils/api";
import {
  SPINNER_COLOR,
  useFetchMemberName,
  usePrimaryColor,
} from "../../utils/utils";
import { Context } from "../Cache";
import * as ACTIONS from "../Cache/actions";
import { withWhitelistAuthorization } from "../Session";
import Button from "../UI/Button";
import { StatusBadge } from "../UI/StatusBadge";
import { withTabBar } from "../UI/TabBar";
import * as itemHelper from "./itemHelper";

const IncidentsList = (props) => {
  const fetchMemberName = useFetchMemberName();
  const primaryColor = usePrimaryColor();
  const [state, dispatch] = useContext(Context);
  const loading = state.incidents == null;

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await api.get(`/incidentsList`);
        dispatch({
          type: ACTIONS.ACTION_INCIDENTS_SET,
          payload: result.data.incidents,
        });
      } catch (e) {
        console.log("loading config failed: ", e);
      }
    }
    fetchData();
  }, []);

  const renderLabelValue = (svg, value, className) => {
    return (
      <div className={cx("flex flex-row items-center", className)}>
        <span className={`text-${primaryColor}-200 mr-1`}>{svg}</span>
        <span
          className="text-gray-900 whitespace-no-wrap overflow-hidden"
          style={{ textOverflow: "ellipsis" }}
        >
          {value}
        </span>
      </div>
    );
  };

  const renderIconItems = (incident) => {
    if (
      !state.config.appearance ||
      !state.config.appearance.listDisplay ||
      state.config.appearance.listDisplay.length === 0
    ) {
      return null;
    }
    const list = state.config.appearance.listDisplay;

    return (
      <div className="flex flex-row flex-wrap justify-between mt-2">
        {list.map((listItem, index) => {
          return (
            <div
              className={cx("text-gray-900 text-base w-1/2", {
                "mb-1": index < list.length - 2,
              })}
            >
              {renderLabelValue(
                itemHelper.getIcon(listItem.icon),
                itemHelper.getValue(incident, listItem.field, fetchMemberName),
                index % 2 !== 0 ? "justify-end" : ""
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderIncidentsList = () => {
    return state.incidents
      .sort((a, b) => {
        if (a.status === b.status) {
          return b.incidentNumber - a.incidentNumber;
        } else if (a.status === "open") {
          return -1;
        } else if (b.status === "open") {
          return 1;
        } else {
          return 0;
        }
      })
      .map((incident) => {
        return (
          <div
            key={incident.id}
            className="w-full p-4 mb-4 bg-white rounded-md overflow-hidden shadow-lg flex flex-col relative select-none cursor-pointer"
            onClick={() => {
              props.history.push(
                ROUTES.INCIDENT_VIEW.replace(":id", incident.id)
              );
            }}
          >
            <div className="text-gray-800 font-semibold tracking-wide uppercase text-sm">
              {`#${incident.incidentNumber}`}
            </div>
            <div className="text-gray-800 font-semibold tracking-wide uppercase text-sm">
              {incident.incidentType}
            </div>
            <div className="text-gray-600 text-sm">
              {format(new Date(incident.reportedAt), "MMM dd, yyyy h:mma")}
            </div>
            {renderIconItems(incident)}
            <StatusBadge
              className="absolute right-0 top-0 mt-2 mr-2"
              incident={incident}
            />
          </div>
        );
      });
  };

  return (
    <div className="flex flex-col px-8 py-4 items-center bg-off-white min-h-screen">
      <div className="fixed w-full text-center bottom-0 mb-20 z-50">
        <Link to={ROUTES.INCIDENT_ADD}>
          <Button title={"File new report"} large rounded shadow />
        </Link>
      </div>

      <div className="text-center uppercase font-semibold tracking-wide mb-2">
        Reports
      </div>
      {loading ? (
        <div className="mt-8">
          <ScaleLoader size={150} color={SPINNER_COLOR} loading={true} />
        </div>
      ) : (
        <div className="max-w-lg w-full pb-32 mt-8">
          {renderIncidentsList()}
        </div>
      )}
    </div>
  );
};

export default withWhitelistAuthorization(withTabBar(IncidentsList), true);
