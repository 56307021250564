import React from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import * as ACTIONS from "../Cache/actions";
import AuthUserContext from "./authContext";
import Loading from "../UI/Loading";
import Navigation from "../UI/Navigation";
import { compose } from "recompose";
import { Context } from "../Cache";
import api from "../../utils/api";
// import Navigation from "../Navigation";

const withAuthorization = (Component, showNav) => {
  class WithAuthorization extends React.Component {
    static contextType = Context;

    async loadDataIfNeeded() {
      try {
        const [state, dispatch] = this.context;

        if (state.incidents !== null && state.memberList !== null) {
          return;
        }

        console.log("loading data if needed");
        const result = await api.get("/userList");
        dispatch({
          type: ACTIONS.ACTION_MEMBERS_SET,
          payload: result.data.users,
        });
      } catch (e) {
        console.log(e);
      }
    }

    componentDidMount() {
      this.loadDataIfNeeded();
      window.scrollTo(0, 0);
      this.listener = this.props.firebase.onAuthUserListener(
        (authUser) => {
          console.log("whitelist callback:", authUser);
          if (!authUser.whitelisted) {
            this.props.history.push(ROUTES.NOT_WHITELISTED);
          }
        },
        () => {
          console.log("redirecting to sign in");
          this.props.history.push(ROUTES.SIGN_IN);
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }
    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => {
            console.log("auth user whitelist: ", authUser);
            return authUser && authUser.whitelisted ? (
              <>
                {showNav ? (
                  <>
                    <Navigation />
                    <div className="h-12"></div>
                  </>
                ) : null}
                <div className="h-screen flex overflow-hidden bg-gray-100">
                  <div className="flex flex-col w-0 flex-1 overflow-hidden">
                    <main
                      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
                      tabIndex="0"
                    >
                      <Component {...this.props} user={authUser} />
                    </main>
                  </div>
                </div>
              </>
            ) : (
              <Loading />
            );
          }}
        </AuthUserContext.Consumer>
      );
    }
  }
  return compose(withRouter, withFirebase)(WithAuthorization);
};
export default withAuthorization;
