export const getPermissionsText = (state, permissions, type) => {
  const key = permissions[type];
  switch (key) {
    case "all-incidents":
      return "all";
    case "own-incidents":
      return "own";
    case "no-incidents":
    case undefined:
    case null:
    case "":
      return "none";
    default:
      return state.config.customPermissions[key]?.title || "none";
  }
};

export const getPermissionOptionData = (state) => {
  const keys = [];
  const titles = [];

  keys.push("all-incidents", "own-incidents", "no-incidents");
  titles.push("All reports", "Own reports", "No reports");

  if (state.config.customPermissions) {
    const customKeys = Object.keys(state.config.customPermissions);
    for (var i = 0; i < customKeys.length; i++) {
      const option = state.config.customPermissions[customKeys[i]];
      keys.push(customKeys[i]);
      titles.push(option.title);
    }
  }
  return { keys, titles };
};
