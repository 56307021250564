import React, { useContext } from "react";
import { usePrimaryColor } from "../../utils/utils";
const cx = require("classnames");

const Button = (props) => {
  const primary = usePrimaryColor();

  return (
    <div
      className={cx(
        "cursor-pointer p-2 hover:bg-cool-gray-100 rounded-md",
        props.color ? props.color : `text-${primary}-500`
      )}
      onClick={props.onClick}
    >
      <svg
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="w-5 h-5"
      >
        {props.icon}
      </svg>
    </div>
  );
};

export default Button;
