import React, { useState } from "react";
import FileViewer from "./FileViewer";
import { renderFile, getFileExtension } from "./index";
const cx = require("classnames");

const Gallery = (props) => {
  const [viewingFile, setViewingFile] = useState(null);

  console.log("images: ", props.files);
  if (!props.files) {
    return null;
  }
  return (
    <>
      <div className="flex flex-row overflow-x-auto overflow-y-hidden max-w-full">
        {props.files.map((file) => {
          return (
            <div
              onClick={() => {
                console.log("setting viewing file: ", file);
                setViewingFile(file);
              }}
              key={file}
              className="cursor-pointer w-20 h-20 mx-2 p-2 flex items-center justify-center flex-shrink-0 border border-gray-300 rounded"
            >
              {renderFile(file, "", false)}
            </div>
          );
        })}
      </div>
      <FileViewer
        showDelete={props.showDelete}
        viewingFile={viewingFile}
        close={() => {
          setViewingFile(null);
        }}
        delete={props.delete}
      />
    </>
  );
};
export default Gallery;
