import { format } from "date-fns";
import { useContext } from "react";
import { Context } from "../components/Cache";

export const processError = (e, defaultString) => {
  return (
    (e && e.response && e.response.data && e.response.data.error) ||
    defaultString
  );
};

export const sentenceCap = (input) => {
  if (!input || input.length === 0 || !input.charAt) {
    return input;
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const ellipsize = (input, max) => {
  return input.length > max ? `${input.substring(0, max - 3)}...` : input;
};

const getFormattedName = (member, short) => {
  if (short) {
    return `${member.firstName} ${member.lastName.charAt(0)}`;
  }
  return `${member.firstName} ${member.lastName}`;
};

function useFetchFieldValueForAnalytics() {
  const fetchMemberName = useFetchMemberName();
  const fetchFieldValue = useFetchFieldValue();
  return (field, incident) => {
    switch (field.type) {
      case "otherMembers": {
        let allMembers = [];
        allMembers.push(fetchMemberName(incident["memberResponding"]));
        if (incident["otherMembers"]) {
          allMembers = allMembers.concat(
            ...incident["otherMembers"].map((otherMember) =>
              fetchMemberName(otherMember)
            )
          );
        }
        console.log("all members: ", allMembers);
        return allMembers;
      }
      default:
        return fetchFieldValue(field, incident);
    }
  };
}

function useFetchFieldValue() {
  const fetchMemberName = useFetchMemberName();
  return (field, incident) => {
    switch (field.type) {
      case "memberResponding":
        return fetchMemberName(incident[field.id]);
      case "otherMembers":
        return incident[field.id]
          ? incident[field.id]
              .map((otherMember) => fetchMemberName(otherMember))
              .join(", ")
          : "";
      case "datetime":
        return incident[field.id]
          ? format(new Date(incident[field.id]), "MMM dd, yyyy h:mma")
          : "";
      default:
        return sentenceCap(incident[field.id]);
    }
  };
}

function useFetchMemberName() {
  const [state, dispatch] = useContext(Context);
  return (id, short) => {
    if (!state.memberList) {
      return id;
    }
    const member = state.memberList.find((member) => member.id === id);
    if (!member) {
      return "=====";
    }

    return getFormattedName(member, short);
  };
}

function usePrimaryColor() {
  const [state, dispatch] = useContext(Context);
  const primary = state.config ? state.config.appearance.primaryColor : "cyan";
  return primary;
}

const SPINNER_COLOR = "#486581";

export {
  getFormattedName,
  usePrimaryColor,
  useFetchMemberName,
  useFetchFieldValue,
  useFetchFieldValueForAnalytics,
  SPINNER_COLOR,
};
