import React, { useState, useContext, useEffect } from "react";
import Transition from "../UI/Transition";
import TextInput from "../UI/TextInput";
import Button from "../UI/Button";
import { Context } from "../Cache";
import api from "../../utils/api";
import { processError } from "../../utils/utils";
import cx from "classnames";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { getOtherMembersSelected } from "../IncidentAdd/formBuilder";
import { getFormattedName } from "../../utils/utils";
import SelectOptions from "../UI/SelectOptions";
import { useProcessIncidentsData } from "./IncidentDataProcess";

const defaultFormState = () => {
  return {};
};

const OnDutyForm = (props) => {
  const [state, dispatch] = useContext(Context);
  const [formData, setFormData] = useState(defaultFormState());
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const processIncidentsData = useProcessIncidentsData();
  const incidentData = processIncidentsData();

  const blacklistTypes = ["files"];
  const availableFields = incidentData.keys
    .map((fieldKey) => {
      if (blacklistTypes.includes(state.config.form.fieldsMap[fieldKey].type)) {
        return null;
      }
      return incidentData.getField(fieldKey);
    })
    .filter((a) => a);

  useEffect(() => {
    console.log("use effect called");
    if (!props.editItem) {
      setFormData(defaultFormState());
      return;
    }
    setFormData({ ...defaultFormState(), ...props.editItem });
  }, [props.editItem]);

  const submitForm = async () => {
    try {
      setLoadingSubmit(true);
      const data = formData;
      await api.post("/admin/charts/update", {
        ...data,
      });
      props.itemAdded();
      setFormData(defaultFormState());
    } catch (e) {
      console.log("error: ", e);
      alert(processError(e, "Error adding item."));
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <Transition
      show={props.show}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed bottom-0 inset-0 p-0 flex items-center justify-center z-50">
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => {
            props.hide();
          }}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
        </div>
        <Transition
          show={props.show}
          enter="transition ease-out duration-300"
          enterFrom="translate-y-0 scale-95"
          enterTo="opacity-100 translate-y-0 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 scale-100"
          leaveTo="opacity-0 translate-y-0 scale-95"
        >
          <div className="bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all max-w-2xl w-full max-h-screen overflow-auto sm:p-6">
            <div className="w-full flex">
              <div className="w-1/2">
                <TextInput
                  padding={false}
                  label={"Chart name"}
                  value={formData.title}
                  onChangeText={(text) => {
                    setFormData({ ...formData, title: text });
                  }}
                />
              </div>
              <div className="w-1/2">
                <SelectOptions
                  padding={false}
                  className="ml-2"
                  label="Group by"
                  unselectedLabel="Select one"
                  value={formData.fieldKey}
                  options={availableFields.map((field) => field.id)}
                  optionLabels={availableFields.map((field) => field.label)}
                  handleChange={(val) => {
                    setFormData({ ...formData, fieldKey: val });
                  }}
                />
              </div>
            </div>
            <Button
              className="mt-4"
              full
              loading={loadingSubmit}
              onClick={() => (loadingSubmit ? null : submitForm())}
              title={"Submit"}
            />
          </div>
        </Transition>
      </div>
    </Transition>
  );
};
export default OnDutyForm;
