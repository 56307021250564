import React from "react";
const cx = require("classnames");

const TextInput = (props) => {
  return (
    <label
      className={cx("block", { "mb-4": !props.noSpacing }, props.className)}
    >
      {props.label ? (
        <span className="text-gray-700">{props.label}</span>
      ) : null}
      <input
        type={props.type || "text"}
        className="form-input block w-full mt-1"
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => {
          props.onChangeText(e.target.value);
        }}
        disabled={props.disabled}
      />
    </label>
  );
};
export default TextInput;
