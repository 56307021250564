import React from "react";
import FileGallery from "./FileGallery";
const cx = require("classnames");
const getFileExtension = (url) => {
  url = url.split("?")[0];
  url = url.split("/").pop();
  return url.split(".").pop();
};

const renderFile = (file, className, showControls) => {
  const extension = getFileExtension(file);
  const imageExtensions = [
    "apng",
    "bmp",
    "gif",
    "ico",
    "jpeg",
    "jpg",
    "png",
    "svg",
    "tiff",
    "webp",
  ];
  const videoExtensions = ["mp4", "ogg", "webm"];
  if (imageExtensions.includes(extension)) {
    return (
      <img
        src={file}
        className={cx("object-contain w-full h-full", className)}
      />
    );
  } else if (videoExtensions.includes(extension)) {
    return (
      <video
        controls={showControls}
        className={cx("object-contain w-full h-full", className)}
      >
        <source src={file} />
      </video>
    );
  } else if (!showControls) {
    return (
      <div className="text-xs text-center flex flex-col items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 block mb-2"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
          <polyline points="13 2 13 9 20 9"></polyline>
        </svg>
        {extension.substring(0, 4).toUpperCase()}
      </div>
    );
  } else {
    return (
      <object
        data={file}
        className={cx("object-contain w-full h-full", className)}
      >
        <div className="flex flex-row items-center justify-center w-full h-full text-white underline text-xl">
          <a href={file} target="_blank" className="p-4">
            Download file
          </a>
        </div>
      </object>
    );
  }
};

export { renderFile, getFileExtension, FileGallery };
