import cx from "classnames";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import api from "../../utils/api";
import { ellipsize } from "../../utils/utils";
import { StatusBadge } from "../UI/StatusBadge";
import { useProcessIncidentsData } from "./IncidentDataProcess";
import IncidentExport from "./IncidentExport";
import IncidentFilter from "./IncidentFilter";
import withAdminWrapper from "./withAdminWrapper";

var qs = require("qs");
const Incidents = (props) => {
  const processIncidentsData = useProcessIncidentsData();
  const [loading, setLoading] = useState(false);
  const [updatingIncident, setUpdatingIncident] = useState(null);
  const [incidents, setIncidents] = useState([]);
  const incidentData = processIncidentsData();

  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  const defaultStartTime = new Date();
  defaultStartTime.setHours(0, 0, 0, 0);
  defaultStartTime.setDate(defaultStartTime.getDate() - 7);

  const defaultEndTime = new Date();
  defaultEndTime.setHours(23, 59, 59, 999);

  const apiParams = {
    page: params.page || 0,
    filterKey: params.filterKey,
    filterValue: params.filterValue,
    sort: params.sort,
    startDate: params.startDate || defaultStartTime.getTime(),
    endDate: params.endDate || defaultEndTime.getTime(),
  };
  const apiParamsString = qs.stringify(apiParams);

  const loadOnDuty = async () => {
    setLoading(true);
    try {
      const result = await api.get(
        "/admin/incidents?" + qs.stringify(apiParams)
      );
      setIncidents(
        result.data.incidents.filter((incident) => {
          if (!apiParams.filterKey) {
            return true;
          }
          console.log(
            `checking if ${incident[apiParams.filterKey]} is ${
              apiParams.filterValue
            }`
          );
          if (apiParams.filterKey === "memberResponding") {
            return (
              incident[apiParams.filterKey] === apiParams.filterValue ||
              incident.otherMembers?.includes(apiParams.filterValue)
            );
          }
          return incident[apiParams.filterKey] === apiParams.filterValue;
        }) || []
      );
      console.log(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const toggleIncidentDeleted = async (index, incident) => {
    console.log("updating incident: ", incident);
    setUpdatingIncident(incident.id);
    try {
      const result = await api.get(
        "/admin/incidents/toggleDeleted?" +
          qs.stringify({ incident: incident.id, setDeleted: !incident.deleted })
      );
      incidents[index] = result.data.incident;
      setIncidents(incidents);
      console.log(result);
    } catch (e) {
      console.log(e);
    } finally {
      setUpdatingIncident(null);
    }
  };

  useEffect(() => {
    loadOnDuty();
  }, [apiParamsString]);

  const renderTh = (contents) => {
    return (
      <th
        className={
          "whitespace-no-wrap px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
        }
      >
        {contents}
      </th>
    );
  };

  const renderTableHeaders = () => {
    return (
      <thead>
        <tr>
          <th className="px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
          <th className="px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
          {incidentData.titles.map((value) => {
            return <>{renderTh(value)}</>;
          })}
        </tr>
      </thead>
    );
  };

  const renderField = (displayValue) => {
    if (!displayValue) {
      return null;
    }
    return (
      <div
        className={cx(
          "text-sm leading-5",
          { "w-96": displayValue.length > 40 },
          { "w-40": displayValue.length >= 10 && displayValue.length <= 40 }
        )}
      >
        {ellipsize(displayValue, 80)}
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-4 lg:-mx-8">
        <IncidentFilter
          incidentData={incidentData}
          apiParams={apiParams}
          loading={loading}
          onSet={(data) => {
            props.history.replace({
              pathname: ROUTES.ADMIN_INCIDENTS,
              search: qs.stringify({
                ...apiParams,
                ...data,
              }),
            });
          }}
        />
        <div className="text-right w-full mb-4">
          <IncidentExport incidents={incidents} />
        </div>
        <div className="align-middle w-full inline-block shadow rounded-lg border-b border-gray-200">
          <div className="w-full overflow-x-scroll">
            <table className="w-full">
              {renderTableHeaders()}
              <tbody className="bg-white">
                {incidents.map((incident, index) => {
                  const columnJsx = [];
                  columnJsx.push(
                    <td className="px-4 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                      <Link
                        className="text-indigo-600 hover:text-indigo-900"
                        target="_blank"
                        to={ROUTES.INCIDENT_VIEW.replace(":id", incident.id)}
                      >
                        View
                      </Link>
                    </td>
                  );
                  columnJsx.push(
                    <td className="px-4 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                      <button
                        className="text-red-600 hover:text-red-900 focus:outline-none"
                        onClick={() => {
                          toggleIncidentDeleted(index, incident);
                        }}
                      >
                        {incident.deleted ? "Restore" : "Delete"}
                      </button>
                    </td>
                  );
                  columnJsx.push(
                    <td className="px-4 py-4 border-b border-gray-200 text-sm leading-5">
                      <div>{`#${incident.incidentNumber}`}</div>
                    </td>
                  );
                  columnJsx.push(
                    <td className="px-4 py-4 text-right border-b border-gray-200 text-sm leading-5">
                      <div className="flex flex-row">
                        <StatusBadge
                          incident={incident}
                          className="inline-block"
                        />
                      </div>
                    </td>
                  );
                  columnJsx.push(
                    <td className="px-4 py-4 border-b border-gray-200 text-sm leading-5">
                      <div className="w-40">
                        {format(
                          new Date(incident.reportedAt),
                          "MMM dd, yyyy h:mma"
                        )}
                      </div>
                    </td>
                  );
                  incidentData.keys.map((key) => {
                    columnJsx.push(
                      <td className="px-4 py-4 border-b border-gray-200 text-sm leading-5">
                        {renderField(
                          incidentData.getDisplayValue(incident, key)
                        )}
                      </td>
                    );
                  });
                  return (
                    <tr className={cx({ "opacity-25": incident.deleted })}>
                      {incident.id === updatingIncident ? (
                        <td
                          colSpan={500}
                          className="px-4 py-4 text-left border-b border-gray-200 text-sm leading-5 font-medium"
                        >
                          Loading...
                        </td>
                      ) : (
                        columnJsx
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAdminWrapper(Incidents, "Reports");
