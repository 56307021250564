import React, { useContext, useState } from "react";
import withAdminWrapper from "./withAdminWrapper";
import MembersTable from "../Members/MembersTable";
import api from "../../utils/api";
import * as ACTIONS from "../Cache/actions";
import { Context } from "../Cache";
import { processError } from "../../utils/utils";
import { subdomain } from "../../utils/api";
import AddMemberForm from "./TeamForm";

const Team = (props) => {
  const [state, dispatch] = useContext(Context);
  const [editMember, setEditMember] = useState(null);

  const reloadTeam = async () => {
    try {
      const result = await api.get("/userList");
      dispatch({
        type: ACTIONS.ACTION_MEMBERS_SET,
        payload: result.data.users,
      });
    } catch (e) {
      console.log("loading config failed: ", e);
    }
  };

  return (
    <>
      <MembersTable
        admin
        openEditModal={(user) => {
          setEditMember(user);
        }}
        showAddMemberForm={() => {
          const dummyUser = props.firebase.db
            .collection("teams")
            .doc(subdomain)
            .collection("users")
            .doc();

          setEditMember({ id: dummyUser.id });
        }}
      />

      <AddMemberForm
        editMember={editMember}
        show={editMember != null}
        hide={() => {
          setEditMember(null);
        }}
        memberAdded={() => {
          setEditMember(null);
          reloadTeam();
        }}
      />
    </>
  );
};
export default withAdminWrapper(Team, "Team");
