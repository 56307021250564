import React, { useEffect, useState } from "react";
import { usePrimaryColor } from "../../utils/utils";
import TextInput from "../UI/TextInput";
import IconButton from "../UI/IconButton";
const cx = require("classnames");

export default (props) => {
  const [tempTitle, setTempTitle] = useState(props.title);

  useEffect(() => {
    setTempTitle(props.title);
  }, [props.title]);

  return (
    <div className={cx("flex flex-row items-center")}>
      <TextInput
        noSpacing
        className="flex-grow w-64 mr-2"
        value={tempTitle}
        placeholder={props.placeholder}
        onChangeText={(val) => {
          setTempTitle(val);
        }}
      />
      <IconButton
        color="text-gray-500"
        onClick={() => {
          console.log("tempTitle: ", tempTitle);
          if (tempTitle === "" || tempTitle === undefined) {
            return;
          }
          props.onButtonClick(tempTitle);
          setTempTitle("");
        }}
        icon={props.icon}
      />
    </div>
  );
};
