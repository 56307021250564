import React from "react";
import { setFirebase } from "../../utils/api";
const FirebaseContext = React.createContext(null);

export const withFirebase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => {
      setFirebase(firebase);
      return <Component {...props} firebase={firebase} />;
    }}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;
