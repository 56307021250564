import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { SPINNER_COLOR } from "../../utils/utils";
const Loading = (props) => {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <ScaleLoader size={150} color={SPINNER_COLOR} loading={true} />
    </div>
  );
};
export default Loading;
