import React from "react";

const TextInput = (props) => {
  return (
    <label className="block mb-4">
      <span className="text-gray-700">{props.label}</span>
      <textarea
        className="form-textarea mt-1 block w-full"
        rows="3"
        disabled={props.disabled}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => {
          props.onChangeText(e.target.value);
        }}
        {...props}
      ></textarea>
    </label>
  );
};
export default TextInput;
