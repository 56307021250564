import React, { useState, useEffect, useContext, useReducer } from "react";
import { withWhitelistAuthorization } from "../Session";
import api from "../../utils/api";
import * as ROUTES from "../../constants/routes";
import Button from "../UI/Button";
import Error from "../UI/Error";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../UI/Loading";
import cx from "classnames";
import { Context } from "../Cache";
import { getFormComponent } from "./formBuilder";
import { processError, useFetchMemberName } from "../../utils/utils";
import * as ACTIONS from "../Cache/actions";

const AddComponent = (props) => {
  const [state, dispatch] = useContext(Context);
  const fetchMemberName = useFetchMemberName();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  //form fields
  const [formData, setFormData] = useState({ memberResponding: props.user.id });
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const isEditing = () => {
    return props.match.params.id;
  };

  const fetchConfig = async () => {
    try {
      const result = await api.get(`/configGet`);
      dispatch({
        type: ACTIONS.ACTION_CONFIG_SET,
        payload: result.data.config,
      });
    } catch (e) {
      console.log("loading config failed: ", e);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setError("");
      try {
        const result = await api.get(
          `/incidentsSearch?id=${props.match.params.id}`
        );
        const incident = result.data.incident;
        dispatch({
          type: ACTIONS.ACTION_INCIDENT_UPDATE,
          payload: incident,
        });
        setFormData(incident);
      } catch (e) {
        setError(processError(e, "Unable to load incident."));
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    if (props.match.params.id) {
      fetchData();
    }
  }, [props.match.params.id]);

  if (loading || (!state.config && isEditing())) {
    return <Loading />;
  } else if (error) {
    return (
      <div className="flex flex-row justify-center">
        <div className="max-w-xl w-full my-16">
          <Error error={error} />
        </div>
      </div>
    );
  }

  const submitForm = async () => {
    try {
      setLoadingSubmit(true);
      const data = formData;
      await api.post("/incidentsAdd", {
        ...data,
      });
      props.history.push(ROUTES.INCIDENTS_LIST);
    } catch (e) {
      console.log("error: ", e);
      alert(
        (e && e.response && e.response.data && e.response.data.error) ||
          "Error adding incident."
      );
    } finally {
      setLoadingSubmit(false);
    }
  };

  const renderTitle = () => {
    if (isEditing()) {
      return (
        <div className="text-center uppercase font-semibold tracking-wide mb-2">
          Editing incident
        </div>
      );
    } else {
      return (
        <div className="text-center uppercase font-semibold tracking-wide mb-2">
          Report a new incident
        </div>
      );
    }
  };

  const renderHeader = (text, hidePadding) => {
    return (
      <div
        className={cx(
          "font-semibold text-xs tracking-wide text-center uppercase w-full pb-4",
          { "pt-6": !hidePadding }
        )}
      >
        {text}
      </div>
    );
  };

  const renderFieldsAndHeaders = () => {
    if (!state.config || !state.config.form.fields) {
      return null;
    }
    const jsx = [];
    const fields = state.config.form.fields;
    const sections = state.config.form.sections;

    for (var i = 0; i < sections.length; i++) {
      const section = sections[i];
      jsx.push(renderHeader(section.title, i == 0));
      const sectionFields = fields[section.id];
      for (var j = 0; j < sectionFields?.length; j++) {
        const field = sectionFields[j];
        jsx.push(
          getFormComponent({
            field,
            formData,
            setFormData,
            user: props.user,
            memberList: state.memberList,
            fetchMemberName,
            firebase: props.firebase,
          })
        );
      }
    }

    return jsx;
  };

  return (
    <div className="bg-off-white rounded flex flex-col justify-center items-center py-4">
      {renderTitle()}
      <div className="max-w-xl w-full bg-white px-8 py-4 rounded shadow-md m-4">
        {renderFieldsAndHeaders()}
        <Button
          full
          loading={loadingSubmit}
          onClick={() => (loadingSubmit ? null : submitForm())}
          title={"Submit"}
        />
      </div>
    </div>
  );
};

export default withWhitelistAuthorization(AddComponent, true);
