import React, { useState, useContext, useEffect } from "react";
import Transition from "../UI/Transition";
import TextInput from "../UI/TextInput";
import Button from "../UI/Button";
import { Context } from "../Cache";
import api from "../../utils/api";
import { processError } from "../../utils/utils";
import cx from "classnames";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { getOtherMembersSelected } from "../IncidentAdd/formBuilder";
import { getFormattedName } from "../../utils/utils";

const defaultFormState = () => {
  return {};
};

const OnDutyForm = (props) => {
  const [state, dispatch] = useContext(Context);
  const [formData, setFormData] = useState(defaultFormState());
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    console.log("use effect called");
    if (!props.editItem) {
      setFormData(defaultFormState());
      return;
    }
    setFormData({ ...defaultFormState(), ...props.editItem });
  }, [props.editItem]);

  const submitForm = async () => {
    try {
      setLoadingSubmit(true);
      const data = formData;
      await api.post("/admin/onDutyUpdate", {
        ...data,
      });
      props.itemAdded();
      setFormData(defaultFormState());
    } catch (e) {
      console.log("error: ", e);
      alert(processError(e, "Error adding item."));
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <Transition
      show={props.show}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed bottom-0 inset-0 p-0 flex items-center justify-center z-50">
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => {
            props.hide();
          }}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
        </div>
        <Transition
          show={props.show}
          enter="transition ease-out duration-300"
          enterFrom="translate-y-0 scale-95"
          enterTo="opacity-100 translate-y-0 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 scale-100"
          leaveTo="opacity-0 translate-y-0 scale-95"
        >
          <div className="bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all max-w-2xl w-full max-h-screen overflow-auto sm:p-6">
            <div className="flex flex-row justify-between items-center mb-2">
              <label className="block w-1/2 flex-shrink mb-4">
                <span className="text-gray-700 mb-1">Start</span>
                <div className="w-full">
                  <DatePicker
                    className="form-input mt-1 block w-full"
                    selected={
                      formData.timeStart ? new Date(formData.timeStart) : null
                    }
                    onChange={(date) => {
                      const obj = { ...formData };
                      obj.timeStart = date && date.toISOString();
                      setFormData(obj);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
              </label>
              <div className="w-8 flex-shrink-0">&nbsp;</div>

              <label className="block w-1/2 flex-shrink mb-4">
                <span className="text-gray-700 mb-1">End</span>
                <div className="w-full">
                  <DatePicker
                    className="form-input mt-1 block w-full"
                    selected={
                      formData.timeEnd ? new Date(formData.timeEnd) : null
                    }
                    onChange={(date) => {
                      const obj = { ...formData };
                      obj.timeEnd = date && date.toISOString();
                      setFormData(obj);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
              </label>
            </div>
            <div className="flex flex-row justify-between items-center mb-2">
              <TextInput
                label={"Rotation title"}
                className="w-1/2 flex-shrink"
                value={formData.title}
                onChangeText={(text) => {
                  const obj = { ...formData };
                  obj.title = text;
                  setFormData(obj);
                }}
              />
              <div className="w-8 flex-shrink-0">&nbsp;</div>

              <label className="block w-1/2 flex-shrink mb-4">
                <span className="text-gray-700 mb-1">Members</span>
                <Select
                  isMulti={true}
                  isDisabled={state.memberList == null}
                  value={getOtherMembersSelected(
                    formData.members,
                    state.memberList
                  )}
                  placeholder={state.memberList == null ? "Loading..." : ""}
                  onChange={(data) => {
                    const members = data
                      ? data.map((dataItem) => dataItem.value)
                      : [];
                    setFormData({
                      ...formData,
                      members,
                    });
                  }}
                  options={
                    state.memberList
                      ? state.memberList.map((member) => {
                          return {
                            value: member.id,
                            label: getFormattedName(member),
                          };
                        })
                      : []
                  }
                />
              </label>
            </div>

            <Button
              className="mt-24"
              full
              loading={loadingSubmit}
              onClick={() => (loadingSubmit ? null : submitForm())}
              title={"Submit"}
            />
          </div>
        </Transition>
      </div>
    </Transition>
  );
};
export default OnDutyForm;
