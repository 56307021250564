import * as Actions from "./actions";

const Reducer = (state, action) => {
  switch (action.type) {
    case Actions.ACTION_ON_DUTY_SET:
      return { ...state, onDutyItems: action.payload };
    case Actions.ACTION_CONFIG_SET:
      const config = processConfig(action.payload);

      return { ...state, config: config };
    case Actions.ACTION_MEMBERS_SET:
      return {
        ...state,
        memberList: action.payload,
      };
    case Actions.ACTION_INCIDENTS_SET:
      return {
        ...state,
        incidents: action.payload,
      };
    case Actions.ACTION_CHARTS_SET:
      return {
        ...state,
        charts: action.payload,
      };
    case Actions.ACTION_INCIDENT_UPDATE:
      if (!state.incidents) {
        return state;
      }

      const updatedIncidents = state.incidents.map((incident) => {
        if (incident.id !== action.payload.id) {
          return incident;
        }
        return action.payload;
      });
      return { ...state, incidents: updatedIncidents };

    default:
      return state;
  }
};

const processConfig = (config) => {
  if (!config.form) {
    return config;
  }

  const fieldsMap = {};

  for (var i = 0; i < config.form.sections.length; i++) {
    const sectionId = config.form.sections[i].id;
    const fields = config.form.fields[sectionId];
    for (var j = 0; j < fields?.length; j++) {
      const field = fields[j];
      fieldsMap[field.id] = field;
    }
  }
  config.form.fieldsMap = fieldsMap;
  return config;
};
export default Reducer;
