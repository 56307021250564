import React from "react";
const cx = require("classnames");

const Checkbox = (props) => {
  return (
    <label className={cx("flex items-center", props.className)}>
      <input
        type="checkbox"
        className="form-checkbox"
        checked={props.checked}
        onChange={(e) => {
          props.onChange(!props.checked);
        }}
      />
      <span className="ml-2 text-gray-700">{props.label}</span>
    </label>
  );
};
export default Checkbox;
