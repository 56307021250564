import { useContext } from "react";
import { useFetchFieldValue } from "../../utils/utils";
import { Context } from "../Cache";

const excludeFields = ["files"];
const extraTitles = ["Number", "Status", "Reported At"];
function useProcessIncidentsData() {
  const [state, dispatch] = useContext(Context);
  const fetchFieldValue = useFetchFieldValue();
  const fieldsMap = state.config.form.fieldsMap;

  let fieldKeys = Object.keys(state.config.form.fieldsMap);
  fieldKeys = fieldKeys.filter((fieldKey) => {
    const field = state.config.form.fieldsMap[fieldKey];
    console.log("looking at field: ", field.type);
    if (excludeFields.includes(field.type)) {
      return false;
    }
    return true;
  });

  return (incident) => {
    //keys
    const keys = fieldKeys;

    // titles
    const titles = fieldKeys.map((key) => {
      return fieldsMap[key].label;
    });
    titles.unshift(...extraTitles);

    //display value
    const getDisplayValue = (incident, key) => {
      return fetchFieldValue(fieldsMap[key], incident);
    };

    const getField = (key) => {
      return fieldsMap[key];
    };

    return { keys, titles, getDisplayValue, getField };
  };
}

export { useProcessIncidentsData, extraTitles };
