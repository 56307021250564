import React, { Component } from "react";
import { withWhitelistAuthorization } from "../Session";
import { withTabBar } from "../UI/TabBar";
import MembersTable from "./MembersTable";

class MemberList extends Component {
  render() {
    return (
      <div className="flex flex-col px-0 md:px-4 pt-4 items-center bg-off-white min-h-screen pb-24">
        <div className="text-center uppercase font-semibold tracking-wide mb-2">
          Members
        </div>
        <MembersTable className="w-full max-w-2xl" />
      </div>
    );
  }
}

export default withWhitelistAuthorization(withTabBar(MemberList), true);
