import React, { useEffect, useContext } from "react";
import SignIn from "../SignIn";
import Account from "../Account";
import IncidentsList from "../IncidentsList";
import IncidentAdd from "../IncidentAdd";
import IncidentView from "../IncidentView";
import AdminTeam from "../Admin/Team";
import AdminIncidents from "../Admin/IncidentBrowser";
import AdminOnDuty from "../Admin/OnDuty";
import AdminAnalytics from "../Admin/Analytics";
import AdminCustomizeForm from "../Admin/CustomizeFormV2";
import Members from "../Members";
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import * as ACTIONS from "../Cache/actions";
import NotWhitelisted from "../NotWhitelisted";
import OnDuty from "../OnDuty";
import Loading from "../UI/Loading";
import { withAuthentication } from "../Session";
import { Context } from "../Cache";
import api from "../../utils/api";

const App = () => {
  const [state, dispatch] = useContext(Context);
  useEffect(() => {
    async function fetchData() {
      try {
        const result = await api.get(`/configGet`);
        dispatch({
          type: ACTIONS.ACTION_CONFIG_SET,
          payload: result.data.config,
        });
      } catch (e) {
        console.log("loading config failed: ", e);
      }
    }
    fetchData();
  }, []);

  if (!state.config) {
    return <Loading />;
  }
  return (
    <Router>
      <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
      <Route exact path={ROUTES.ACCOUNT} component={Account} />
      <Route exact path={ROUTES.INCIDENTS_LIST} component={IncidentsList} />
      <Route exact path={ROUTES.INCIDENT_ADD} component={IncidentAdd} />
      <Route exact path={ROUTES.INCIDENT_EDIT} component={IncidentAdd} />
      <Route exact path={ROUTES.INCIDENT_VIEW} component={IncidentView} />
      <Route exact path={ROUTES.NOT_WHITELISTED} component={NotWhitelisted} />
      <Route exact path={ROUTES.MEMBER_LIST} component={Members} />
      <Route exact path={ROUTES.ON_DUTY} component={OnDuty} />

      <Route exact path={ROUTES.ADMIN_TEAM} component={AdminTeam} />
      <Route exact path={ROUTES.ADMIN_INCIDENTS} component={AdminIncidents} />
      <Route exact path={ROUTES.ADMIN_ON_DUTY} component={AdminOnDuty} />
      <Route exact path={ROUTES.ADMIN_ANALYTICS} component={AdminAnalytics} />
      <Route
        exact
        path={ROUTES.ADMIN_CUSTOMIZE_FORM}
        component={AdminCustomizeForm}
      />
    </Router>
  );
};
export default withAuthentication(App);
