import React, { useState } from "react";
import Button from "../UI/Button";
import FileViewer from "./FileViewer";
import { v4 as uuidv4 } from "uuid";
import { subdomain } from "../../utils/api";
import { getFileExtension, renderFile } from "./index";
const FileType = require("file-type");
const toBuffer = require("typedarray-to-buffer");

const cx = require("classnames");

const convertBlobToBuffer = (blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("loadend", (event) => {
      let data = event.target.result;
      if (data instanceof ArrayBuffer) {
        data = toBuffer(new Uint8Array(event.target.result));
      }

      resolve(data);
    });

    fileReader.addEventListener("error", (event) => {
      reject(new Error(event.message));
    });

    fileReader.addEventListener("abort", (event) => {
      reject(new Error(event.type));
    });

    fileReader.readAsArrayBuffer(blob);
  });
};

const parseFileExtension = async (file) => {
  try {
    const buffer = await convertBlobToBuffer(file.slice(0, 4100));
    const fileType = await FileType.fromBuffer(buffer);
    console.log(fileType);
    if (!fileType) {
      return "txt";
    }
    return fileType.ext;
  } catch (e) {
    console.log(e);
  }
};

const ImageViewer = (props) => {
  const [loading, setLoading] = useState(false);
  const [viewingIndex, setViewingIndex] = useState(-1);
  const uploadFile = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    let extension = file.name.split(".").pop().toLowerCase();
    if (file.name.split(".").length === 1) {
      extension = await parseFileExtension(file);
    }
    const fileName = `${uuidv4()}.${extension}`;
    console.log(fileName);

    const uploadTask = props.firebase.storage
      .ref(`/${subdomain}/${fileName}`)
      .put(file);
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        console.log(snapShot);
      },
      (err) => {
        //catches the errors
        console.log(err);
        setLoading(false);
      },
      () => {
        props.firebase.storage
          .ref(subdomain)
          .child(fileName)
          .getDownloadURL()
          .then((url) => {
            console.log(url);
            props.files.push(url);
            props.onChange(props.files);
          });
        setLoading(false);
      }
    );
  };

  console.log("images: ", props.files);
  return (
    <div>
      <div
        className={cx("flex flex-row h-20 mb-4 items-center", {
          "justify-center": props.files.length === 0,
        })}
      >
        <div className="flex flex-row overflow-x-auto cursor-pointer">
          {props.files.map((file, index) => {
            return (
              <div
                onClick={() => {
                  console.log("setting viewing file: ", file);
                  setViewingIndex(index);
                }}
                key={file}
                className="w-20 h-20 mx-2 p-2 flex items-center justify-center flex-shrink-0 border border-gray-300 rounded"
              >
                {renderFile(file)}
              </div>
            );
          })}
        </div>
        <div
          className={cx("flex-shrink-0", { "ml-4": props.files.length > 0 })}
        >
          <Button title={"Add"} flat type="label" loading={loading}>
            <input
              type="file"
              className="hidden"
              onChange={uploadFile}
              value=""
            />
          </Button>
        </div>
      </div>
      <div className="text-xs text-center text-gray-400 mt-2 mb-4">
        Press the "submit" button at the bottom of the page to save uploaded
        files.
      </div>
      <div>
        <FileViewer
          showDelete={true}
          viewingFile={props.files[viewingIndex] || null}
          close={() => {
            setViewingIndex(-1);
          }}
          delete={() => {
            props.files.splice(viewingIndex, 1);
            props.onChange(props.files);
          }}
        />
      </div>
    </div>
  );
};
export default ImageViewer;
export { renderFile, getFileExtension };
