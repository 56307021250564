export const SIGN_IN = "/";
export const ON_DUTY = "/on-duty";
export const ACCOUNT = "/account";
export const INCIDENTS_LIST = "/incidents";
export const MEMBER_LIST = "/members";
export const INCIDENT_ADD = "/incidents/add";
export const INCIDENT_EDIT = "/incidents/edit/:id";
export const INCIDENT_VIEW = "/incidents/view/:id";
export const NOT_WHITELISTED = "/not-whitelisted";

export const ADMIN_TEAM = "/admin";
export const ADMIN_INCIDENTS = "/admin/incidents";
export const ADMIN_ANALYTICS = "/admin/analytics";
export const ADMIN_ON_DUTY = "/admin/on-duty";
export const ADMIN_CUSTOMIZE_FORM = "/admin/customize-form";
