import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { usePrimaryColor } from "../../utils/utils";
import { Context } from "../Cache";
const cx = require("classnames");

const Tab = (props, currentPath) => {
  const primaryColor = usePrimaryColor();
  const selected = currentPath.includes(props.to);
  return (
    <Link
      to={props.to}
      className={cx(
        "w-1/3 flex flex-col items-center justify-center py-2 font-semibold",
        { [`text-${primaryColor}-500`]: selected },
        { "text-gray-500": !selected }
      )}
    >
      <div className="mb-1">{props.svg}</div>
      <div className="text-xs">{props.title}</div>
    </Link>
  );
};

const TabBar = (props) => {
  const [state, dispatch] = useContext(Context);
  let location = useLocation();
  return (
    <div
      className={cx(
        "w-full flex flex-row bg-white shadow-md border-gray-300 border-t-2",
        props.className
      )}
    >
      {state.config.appearance.widgets &&
      state.config.appearance.widgets.includes("onDuty")
        ? Tab(
            {
              svg: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                  <line x1="12" y1="9" x2="12" y2="13"></line>
                  <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>
              ),
              title: "On duty",
              to: ROUTES.ON_DUTY,
            },
            location.pathname
          )
        : null}
      {Tab(
        {
          svg: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="8" y1="6" x2="21" y2="6"></line>
              <line x1="8" y1="12" x2="21" y2="12"></line>
              <line x1="8" y1="18" x2="21" y2="18"></line>
              <line x1="3" y1="6" x2="3.01" y2="6"></line>
              <line x1="3" y1="12" x2="3.01" y2="12"></line>
              <line x1="3" y1="18" x2="3.01" y2="18"></line>
            </svg>
          ),
          title: "Reports",
          to: ROUTES.INCIDENTS_LIST,
        },
        location.pathname
      )}
      {Tab(
        {
          svg: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
          ),
          title: "Members",
          to: ROUTES.MEMBER_LIST,
        },
        location.pathname
      )}
      {Tab(
        {
          svg: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="3"></circle>
              <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
            </svg>
          ),
          title: "Account",
          to: ROUTES.ACCOUNT,
        },
        location.pathname
      )}
    </div>
  );
};

const withTabBar = (Component) => {
  class WithTabBar extends React.Component {
    render() {
      return (
        <>
          <TabBar className="fixed w-full text-center bottom-0 z-50" />
          <Component {...this.props} className="flex-grow" />
        </>
      );
    }
  }
  return WithTabBar;
};

export { withTabBar };
