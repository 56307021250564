import cx from "classnames";
import React from "react";
const SelectOptions = ({
  options,
  optionLabels,
  label,
  value,
  disabled,
  handleChange,
  unselectedLabel,
  className,
  padding = true,
}) => {
  return (
    <label className={cx("block", { "mb-4": padding }, className)}>
      {label ? <span className="text-gray-700">{label}</span> : null}
      <select
        value={value}
        onChange={(e) => {
          console.log("e.target", e.target);
          handleChange(e.target.value);
        }}
        className={cx("form-select block w-full", { "mt-1": label })}
        disabled={disabled}
      >
        <option key={""} value={""}>
          {unselectedLabel || "Select one"}
        </option>
        {options.map((option, i) => {
          const display = optionLabels ? optionLabels[i] : option;
          return (
            <option key={option} value={option}>
              {(display.charAt(0).toUpperCase() + display.slice(1)).replace(
                /-/g,
                " "
              )}
            </option>
          );
        })}
      </select>
    </label>
  );
};
export default SelectOptions;
