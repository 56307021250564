import app from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import api from "../../utils/api";
import config from "../../utils/config";

class Firebase {
  constructor() {
    app.initializeApp(config.firebase);

    this.auth = app.auth();
    this.storage = app.storage();
    this.db = app.firestore();
  }

  db = () => {
    return this.db;
  };

  storage = () => {
    return this.storage;
  };

  doSignInWithPhone = (number, verifier) => {
    return this.auth.signInWithPhoneNumber(number, verifier);
  };

  doSignOut = () => {
    return this.auth.signOut();
  };

  getCurrentUserToken = () => {
    return this.auth.currentUser.getIdToken();
  };

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        try {
          const token = await authUser.getIdToken();
          const response = await api.get("/userSearch");
          authUser = { whitelisted: true, ...response.data.user, token };
          next(authUser);
        } catch (e) {
          if (e.response && e.response.status === 403) {
            authUser = { whitelisted: false };
            next(authUser);
          } else {
            fallback();
          }
        }
      } else {
        fallback();
      }
    });
}
export default Firebase;
