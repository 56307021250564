import React, { useState, useRef, useEffect } from "react";
import rawCountries from "../../constants/rawCountries";
import Select from "react-select";
import cx from "classnames";

const formatNumber = (input, formatMatch) => {
  let onlyNumbers = input.replace(/[^0-9.]/g, "");
  if (!formatMatch) {
    return onlyNumbers;
  }
  let outputString = "";
  let currentIndex = 0;
  for (var i = 0; i < formatMatch.length; i++) {
    switch (formatMatch.charAt(i)) {
      case ".":
        outputString += onlyNumbers.charAt(currentIndex);
        currentIndex++;
        break;
      default:
        outputString += formatMatch.charAt(i);
        break;
    }
  }
  outputString = outputString.replace(/[^0-9]*$/, "");
  return outputString;
};

const getPlainPhoneNumber = (selectedCountry, number) => {
  return `+${selectedCountry.country[3]}${number.replace(/[^0-9.]/g, "")}`;
};

const PhoneNumberInput = (props) => {
  const [selectedCountry, setSelectedCountry] = useState({
    country: ["Kenya", ["africa"], "ke", "254", "... ...-..."],
  });
  const [number, setNumber] = useState("");
  const inputPhoneRef = useRef(null);

  useEffect(() => {
    if (!props.defaultValue) {
      return;
    }

    console.log("defaultValue: ", props.defaultValue);

    let defaultCountry = ["Kenya", ["africa"], "ke", "254", "... ...-..."];
    rawCountries.map((country) => {
      if (props.defaultValue.startsWith("+" + country[3])) {
        defaultCountry = country;
      }
    });
    setSelectedCountry({ country: defaultCountry });

    const rawNumber = props.defaultValue.slice(
      defaultCountry[3].length + 1,
      props.defaultValue.length
    );
    const formattedNumber = formatNumber(rawNumber, defaultCountry[4]);
    setNumber(formattedNumber);
  }, [props.defaultValue]);

  const selectedFormat =
    selectedCountry && selectedCountry.country && selectedCountry.country[4];
  var placeholder = "";
  if (selectedFormat) {
    placeholder = selectedCountry.country[4].replace(/\./g, "0");
  }
  return (
    <label className={cx("block", props.className)}>
      <span className="text-gray-700">Phone number</span>
      <div className="w-full mt-1 flex items-center">
        <Select
          className="flex-shrink-0 w-28 mr-1"
          styles={{
            menu: (provided, state) => ({
              ...provided,
              width: "16rem",
            }),
          }}
          value={selectedCountry}
          onChange={(data) => {
            setSelectedCountry(data.value);
            setNumber("");
            inputPhoneRef.current.focus();
            props.onPhoneNumberUpdated(getPlainPhoneNumber(data.value, number));
          }}
          formatOptionLabel={(option, { context }) => {
            if (context === "value") {
              return option.country ? "+" + option.country[3] : "";
            }
            return option.label;
          }}
          options={rawCountries.map((country) => {
            return {
              value: { country },
              label: `${country[0]} +${country[3]}`,
            };
          })}
        />
        <input
          type="tel"
          className="form-input block w-full"
          value={number}
          ref={inputPhoneRef}
          placeholder={placeholder}
          onChange={(e) => {
            const formattedNumber = formatNumber(
              e.target.value,
              selectedFormat
            );
            console.log("selectedFormat: ", selectedFormat);
            console.log("formatted: ", formattedNumber);
            setNumber(formattedNumber);
            props.onPhoneNumberUpdated(
              getPlainPhoneNumber(selectedCountry, formattedNumber)
            );
          }}
        />
      </div>
    </label>
  );
};
export default PhoneNumberInput;
