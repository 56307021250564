import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { Context } from "../Cache";
import Transition from "../UI/Transition";

const Component = (props) => {
  const [state, dispatch] = useContext(Context);
  const [navOpen, setNavOpen] = useState(false);

  const getLink = ({ title, icon, href }) => {
    return (
      <>
        <Link to={href}>
          <div className="group flex items-center px-2 py-2 text-base leading-6 md:text-sm md:leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {icon}
            </svg>
            {title}
          </div>
        </Link>
      </>
    );
  };

  const getLinks = () => {
    return (
      <nav className="flex-1 px-2 py-4 bg-white">
        {/* {getLink({
          title: "Dashboard",
          icon: (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
            />
          ),
        })} */}
        {state.config.appearance.widgets.includes("onDuty")
          ? getLink({
              href: ROUTES.ADMIN_ON_DUTY,
              title: "On Duty",
              icon: (
                <>
                  <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                  <line x1="12" y1="9" x2="12" y2="13"></line>
                  <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </>
              ),
            })
          : null}

        {getLink({
          href: ROUTES.ADMIN_CUSTOMIZE_FORM,
          title: "Customize Form",
          icon: (
            <>
              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
            </>
          ),
        })}
        {getLink({
          href: ROUTES.ADMIN_INCIDENTS,
          title: "Reports",
          icon: (
            <>
              <line x1="8" y1="6" x2="21" y2="6"></line>
              <line x1="8" y1="12" x2="21" y2="12"></line>
              <line x1="8" y1="18" x2="21" y2="18"></line>
              <line x1="3" y1="6" x2="3.01" y2="6"></line>
              <line x1="3" y1="12" x2="3.01" y2="12"></line>
              <line x1="3" y1="18" x2="3.01" y2="18"></line>
            </>
          ),
        })}
        {getLink({
          href: ROUTES.ADMIN_TEAM,
          title: "Team",
          icon: (
            <path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
          ),
        })}
        {getLink({
          title: "Analytics",
          href: ROUTES.ADMIN_ANALYTICS,
          icon: (
            <>
              <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
              <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
            </>
          ),
        })}
        {getLink({
          title: "Exit to main app",
          href: ROUTES.INCIDENTS_LIST,
          icon: (
            <>
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7"></polyline>
              <line x1="21" y1="12" x2="9" y2="12"></line>
            </>
          ),
        })}
        {/* {getLink({
          title: "Reports",
          icon: (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          ),
        })} */}
      </nav>
    );
  };

  const getCommonNav = () => {
    return (
      <div className="pointer-events-auto relative flex-1 max-w-xs md:max-w-none w-full md:w-64 pt-5 md:pt-0 pb-4 md:pb-0 flex flex-col bg-white border-gray-200 border-r">
        <div className="md:hidden absolute top-0 right-0 -mr-14 p-1">
          <button
            onClick={() => {
              setNavOpen(false);
            }}
            className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
            aria-label="Close sidebar"
          >
            <svg
              className="h-6 w-6 text-white"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <Link to={ROUTES.ADMIN_TEAM}>
          <div className="flex flex-col items-center flex-shrink-0 py-4 px-2">
            <img
              className="h-8 w-auto mb-2"
              src={state.config && state.config.appearance.logo}
            />
            <span className="font-medium text-center">
              {state.config && state.config.appearance.name}
              <br />
              Admin Dashboard
            </span>
          </div>
        </Link>
        <div className="mt-5 md:mt-0 h-0 flex-1 flex flex-col overflow-y-auto">
          {getLinks()}
        </div>
      </div>
    );
  };

  const getMobileNav = () => {
    return (
      <div className="md:hidden">
        <div className="fixed inset-0 flex z-40 pointer-events-none">
          <Transition
            show={navOpen}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 md:hidden pointer-events-none">
              <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
            </div>
          </Transition>
          <Transition
            show={navOpen}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            {getCommonNav()}
          </Transition>

          <div className="flex-shrink-0 w-14"></div>
        </div>
      </div>
    );
  };

  const getDesktopNav = () => {
    return (
      <div className="hidden md:flex md:flex-shrink-0">{getCommonNav()}</div>
    );
  };

  return (
    <>
      {getMobileNav()}
      {getDesktopNav()}
      <div className="flex flex-col w-0 flex-1">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            onClick={() => {
              setNavOpen(true);
            }}
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
            aria-label="Open sidebar"
          >
            <svg
              className="h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        {props.children}
      </div>
    </>
  );
};
export default Component;
