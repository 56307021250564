import React, { useContext } from "react";
import { usePrimaryColor } from "../../utils/utils";
const cx = require("classnames");

const OuterComponent = (props) => {
  if (props.type === "label") {
    return <label {...props}>{props.children}</label>;
  }
  return <button {...props}>{props.children}</button>;
};

const Button = (props) => {
  const primary = usePrimaryColor();

  return (
    <OuterComponent
      disabled={props.loading || props.disabled}
      {...props}
      className={cx(
        "font-medium uppercase focus:outline-none cursor-pointer text-sm",
        {
          [`bg-${primary}-500 text-white hover:bg-${primary}-600`]:
            !props.flat && !props.secondary,
        },
        {
          "bg-white border border-gray-300 text-gray-500 hover:text-gray-500 hover:border-gray-600":
            props.flat,
        },
        {
          [`border-2 border-gray-500 text-white hover:border-gray-200`]: props.secondary,
        },
        {
          "shadow-lg": props.shadow,
        },
        {
          "rounded-full": props.rounded,
          rounded: !props.rounded,
        },
        {
          "py-2 px-4": !props.large,
          "py-4 px-6": props.large,
        },
        {
          "opacity-50 cursor-not-allowed": props.loading || props.disabled,
          "w-full": props.full,
        },
        props.className
      )}
    >
      {props.loading ? "Loading..." : props.title}
      {props.children}
    </OuterComponent>
  );
};
export default Button;
