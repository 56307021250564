import React from "react";
import { sentenceCap, ellipsize } from "../../utils/utils";

const className = "fill-current h-5 w-5";

export const getValue = (incident, field, fetchMemberName) => {
  switch (field) {
    case "memberResponding":
      return fetchMemberName(incident.memberResponding, true);
    case "city":
      return sentenceCap(incident.city);
    default:
      return incident[field];
  }
};

export const getIcon = (key) => {
  switch (key) {
    case "truck":
      return (
        <svg className={className} viewBox="0 0 24 24">
          <rect x="1" y="3" width="15" height="13"></rect>
          <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
          <circle cx="5.5" cy="18.5" r="2.5"></circle>
          <circle cx="18.5" cy="18.5" r="2.5"></circle>
        </svg>
      );
    case "city":
      return (
        <svg className={className} viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M19,15H17V13H19M19,19H17V17H19M13,7H11V5H13M13,11H11V9H13M13,15H11V13H13M13,19H11V17H13M7,11H5V9H7M7,15H5V13H7M7,19H5V17H7M15,11V5L12,2L9,5V7H3V21H21V11H15Z"
          />
        </svg>
      );
    case "location":
      return (
        <svg className={className} viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
          />
        </svg>
      );
    case "profile":
      return (
        <svg className={className} viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
          />
        </svg>
      );
    default:
      return null;
  }
};
