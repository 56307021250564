import React, { useState, useEffect } from "react";
import Button from "../UI/Button";
import { withTabBar } from "../UI/TabBar";
import { withWhitelistAuthorization } from "../Session";
import * as ROUTES from "../../constants/routes";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import api from "../../utils/api";
import SelectOptions from "../UI/SelectOptions";
import { processError } from "../../utils/utils";
import { defaultProps } from "recompose";

const renderLabelValue = (label, value, full) => {
  return (
    <div className={cx("mb-6", { "w-1/2": !full })} key={label}>
      <div className="text-gray-600 uppercase text-xs tracking-wide">
        {label}
      </div>
      <div>{value}</div>
    </div>
  );
};

const renderHeader = (text, hidePadding) => {
  return (
    <div
      className={cx("font-semibold tracking-wide uppercase pb-4", {
        "pt-6": !hidePadding,
      })}
    >
      {text}
    </div>
  );
};

const Account = ({ firebase, history, user }) => {
  useEffect(() => {
    setNotifications(user.notifications);
  }, [user]);
  const [notifications, setNotifications] = useState("none");
  const [loading, setLoading] = useState(false);

  const updateNotificationPrefs = async () => {
    try {
      setLoading(true);
      await api.post("/userEdit", {
        notifications: notifications,
      });
      window.location.reload();
    } catch (e) {
      console.log("error: ", e);
      alert(processError(e, "Error updating account."));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col px-0 md:px-4 pt-4 items-center bg-off-white min-h-screen pb-24">
      <div className="text-center uppercase font-semibold tracking-wide mb-2">
        Account
      </div>

      <div className="max-w-xl w-full bg-white px-8 py-4 rounded shadow-md m-4">
        {renderHeader("Profile", true)}
        <div className="flex flex-wrap">
          {renderLabelValue("First name", user.firstName, false)}
          {renderLabelValue("Last name", user.lastName, false)}
          {renderLabelValue("Phone number", user.phoneNumber, false)}
          {renderLabelValue("Email", user.email, false)}
          {renderLabelValue("City", user.city, false)}
          {renderLabelValue("Call sign", user.callSign, false)}
        </div>
        {user.admin ? (
          <div className="w-full flex flex-row justify-center mb-4">
            <Button
              title={"Go to admin dashboard"}
              flat
              onClick={() => {
                history.push(ROUTES.ADMIN_TEAM);
              }}
            />
          </div>
        ) : null}

        <hr className="w-full" />
        {renderHeader("Notification preferences")}
        <div className="flex items-end">
          <SelectOptions
            className="mr-4"
            padding={false}
            label={"Receiving notifications via: "}
            value={notifications}
            handleChange={(value) => {
              setNotifications(value);
            }}
            options={["none", "sms", "email", "whatsapp"]}
            optionLabels={["none", "SMS", "email", "WhatsApp"]}
          />
          <Button
            loading={loading}
            title={"Update"}
            flat
            onClick={() => {
              updateNotificationPrefs();
            }}
          />
        </div>
        <div className="text-center mt-12">
          <Button
            title={"Sign out"}
            onClick={async () => {
              await firebase.doSignOut();
              localStorage.removeItem("authUser");
              history.push(ROUTES.SIGN_IN);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default withWhitelistAuthorization(
  withRouter(withTabBar(Account)),
  true
);
