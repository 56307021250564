import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import api from "../../utils/api";
import { processError, useFetchFieldValue } from "../../utils/utils";
import { Context } from "../Cache";
import * as ACTIONS from "../Cache/actions";
import { FileGallery } from "../FileHandlers/index";
import { withWhitelistAuthorization } from "../Session";
import Button from "../UI/Button";
import Error from "../UI/Error";
import Loading from "../UI/Loading";
import { StatusBadge } from "../UI/StatusBadge";
import Comments from "./comments";
import IncidentHistory from "./incidentHistory";

const AddComponent = (props) => {
  const fetchFieldValue = useFetchFieldValue();
  const [loading, setLoading] = useState(true);
  const [incident, setIncident] = useState(null);
  const [error, setError] = useState("");
  const [state, dispatch] = useContext(Context);

  const fetchConfig = async () => {
    try {
      const result = await api.get(`/configGet`);
      dispatch({
        type: ACTIONS.ACTION_CONFIG_SET,
        payload: result.data.config,
      });
    } catch (e) {
      console.log("loading config failed: ", e);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setError("");
      try {
        const tempIncident =
          state.incidents != null &&
          state.incidents.find((incident) => {
            return incident.id === props.match.params.id;
          });

        if (tempIncident) {
          setIncident(tempIncident);
        }

        const result = await api.get(
          `/incidentsSearch?id=${props.match.params.id}`
        );
        const incident = result.data.incident;
        dispatch({
          type: ACTIONS.ACTION_INCIDENT_UPDATE,
          payload: incident,
        });
        setIncident(incident);
      } catch (e) {
        setError(processError(e, "Unable to load incident."));
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    if (props.match.params.id) {
      fetchData();
    }
  }, [props.match.params.id]);

  if (loading || !state.config) {
    return <Loading />;
  } else if (error) {
    return (
      <div className="flex flex-row justify-center">
        <div className="max-w-xl w-full my-16">
          <Error error={error} />
        </div>
      </div>
    );
  }

  console.log("incident: ", incident);
  const renderHeader = (text, hidePadding) => {
    return (
      <div
        className={cx("font-semibold tracking-wide uppercase pb-4", {
          "pt-6": !hidePadding,
        })}
      >
        {text}
      </div>
    );
  };

  const renderLabelValue = (label, value, full) => {
    return (
      <div
        className={cx("mb-6", { "w-5/12": !full }, { "w-full": full })}
        key={label}
      >
        <div className="text-gray-600 uppercase text-xs tracking-wide">
          {label}
        </div>
        <div>{value}</div>
      </div>
    );
  };

  const renderFields = (fields) => {
    return (
      <div className="flex flex-wrap justify-between">
        {fields.map((field) => {
          if (!field.value) {
            return null;
          } else if (field.type === "files") {
            console.log("rendering files: ", field);
            return (
              <div className="mb-4 w-full">
                <FileGallery files={field.value} showDelete={false} />
              </div>
            );
          } else {
            return renderLabelValue(
              field.label,
              field.value,
              field.type === "textArea"
            );
          }
        })}
      </div>
    );
  };

  const renderAllFields = () => {
    if (!state.config || !state.config.form || !state.config.form.fields) {
      return null;
    }
    const jsx = [];
    const fields = state.config.form.fields;
    const sections = state.config.form.sections;

    for (var i = 0; i < sections.length; i++) {
      const section = sections[i];
      jsx.push(renderHeader(section.title, i == 0));
      const sectionFields = fields[section.id];
      if (sectionFields && sectionFields.length > 0) {
        jsx.push(
          renderFields(
            sectionFields.map((sectionField) => {
              return {
                label: sectionField.label,
                type: sectionField.type,
                value: fetchFieldValue(sectionField, incident),
              };
            })
          )
        );
      }

      if (i < sections.length - 1) {
        jsx.push(<hr />);
      }
    }
    return jsx;
  };

  return (
    <>
      <div className="bg-off-white rounded flex flex-col justify-center items-center pt-4 pb-24">
        <div className="max-w-xl w-full bg-white px-8 py-4 rounded shadow-md m-4 relative">
          <div className="text-gray-800 font-semibold tracking-wide uppercase text-sm mb-4">
            {`#${incident.incidentNumber}`}
          </div>
          {renderAllFields()}

          <StatusBadge
            className="absolute right-0 top-0 mt-4 mr-4"
            incident={incident}
          />
        </div>
        <Comments {...props} incident={incident} />
        <IncidentHistory {...props} />
      </div>

      {incident.canEdit ? (
        <div className="fixed w-full text-center bottom-0 mb-8 z-40">
          <Link to={ROUTES.INCIDENT_EDIT.replace(":id", incident.id)}>
            <Button title={"Edit reports"} large rounded shadow />
          </Link>
        </div>
      ) : null}
    </>
  );
};

export default withWhitelistAuthorization(AddComponent, true);
