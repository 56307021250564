import React, { useContext, useState, useEffect, useRef } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import api from "../../utils/api";
import { Context } from "../Cache";
import withAdminWrapper from "./withAdminWrapper";
import Button from "../UI/Button";
import EditableRow from "../UI/EditableRow";
import { SPINNER_COLOR } from "../../utils/utils";
import cx from "classnames";
import { v4 as uuidv4 } from "uuid";
import Transition from "../UI/Transition";
import { Badge } from "../UI/StatusBadge";
import Checkbox from "../UI/Checkbox";
import TextInputWithButton from "../UI/TextInputWithButton";

const arrayMove = (arr, old_index, new_index) => {
  if (new_index < 0 || new_index > arr.length - 1) {
    return arr;
  }
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing purposes
};

const useOutsideClick = (ref, func) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const CustomizeForm = (props) => {
  const [state, dispatch] = useContext(Context);
  const [form, setForm] = useState(state.config?.form);
  const [selectedSectionId, setSelectedSectionId] = useState(-1);
  const [showAddMenu, setShowAddMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    updateSelection(form, true);
  }, []);

  const updateSelection = (form, resetSelectedSection) => {
    if (resetSelectedSection) {
      setSelectedSectionId(
        form.sections && form.sections.length > 0 ? form.sections[0].id : -1
      );
    } else {
      setSelectedSectionId(
        form.sections && form.sections.length > 0 && selectedSectionId === -1
          ? form.sections[0].id
          : selectedSectionId
      );
    }
  };

  const updateForm = async (form, resetSelectedSection) => {
    setLoading(true);
    const formCopy = { ...form };
    delete formCopy.fieldsMap;
    try {
      const result = await api.post("/admin/customizeForm/update", {
        form: formCopy,
      });
      setForm(result.data);
      updateSelection(result.data, resetSelectedSection);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const resetCurrentEdits = (form, resetSelectedSection = false) => {
    if (form) {
      setForm(form);
    }
    updateForm(form, resetSelectedSection);
  };

  const renderSections = () => {
    return form.sections.map((section, i) => {
      return (
        <div
          className={cx("mb-2 px-2 pt-1 pb-2 rounded", {
            "bg-gray-100": selectedSectionId === section.id,
          })}
        >
          <EditableRow
            onClick={() => {
              setSelectedSectionId(section.id);
            }}
            title={section.title}
            onTextUpdate={(text) => {
              const updatedForm = { ...form };
              updatedForm.sections[i].title = text;
              resetCurrentEdits(updatedForm);
            }}
            onUpClick={() => {
              const updatedForm = { ...form };
              arrayMove(updatedForm.sections, i, i - 1);
              resetCurrentEdits(updatedForm);
            }}
            onDownClick={() => {
              const updatedForm = { ...form };
              arrayMove(updatedForm.sections, i, i + 1);
              resetCurrentEdits(updatedForm);
            }}
            onDeleteClick={() => {
              if (form.fields[selectedSectionId]?.length > 0) {
                window.alert(
                  "There are active fields in this section, please delete them first."
                );
                return;
              } else if (window.confirm("Are you sure want to delete this?")) {
                const updatedForm = { ...form };
                updatedForm.sections.splice(i, 1);
                delete updatedForm.fields[selectedSectionId];
                resetCurrentEdits(updatedForm, true);
              }
            }}
          />
        </div>
      );
    });
  };

  const renderDropdownItem = (title, type) => {
    return (
      <div
        className="cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
        role="menuitem"
        onClick={() => {
          console.log("section: ", selectedSectionId);
          if (selectedSectionId === -1) {
            window.alert("Please select a section first.");
            return;
          }
          console.log("clicked");
          const updatedForm = { ...form };
          if (!updatedForm.fields[selectedSectionId]) {
            updatedForm.fields[selectedSectionId] = [];
          }
          updatedForm.fields[selectedSectionId].push({
            id: uuidv4(),
            section: selectedSectionId,
            type: type,
            label: title + " Title",
          });
          console.log(updatedForm);
          setShowAddMenu(false);
          resetCurrentEdits(updatedForm);
        }}
      >
        {title}
      </div>
    );
  };

  const formFieldTypes = {
    memberResponding: "Member",
    otherMembers: "Multiple member selection",
    text: "Short text",
    textArea: "Long text",
    datetime: "Date/time",
    dropdown: "Dropdown",
    files: "File upload",
  };

  const AddItemButton = () => {
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
      setShowAddMenu(false);
    });

    return (
      <div className="relative inline-block text-left z-50" ref={wrapperRef}>
        <div>
          <span className="rounded-md shadow-sm">
            <button
              type="button"
              onClick={() => {
                setShowAddMenu(!showAddMenu);
              }}
              className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
            >
              Add item
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </span>
        </div>

        <Transition
          show={showAddMenu}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
            <div className="rounded-md bg-white shadow-xs">
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {Object.keys(formFieldTypes).map((fieldType) => {
                  return renderDropdownItem(
                    formFieldTypes[fieldType],
                    fieldType
                  );
                })}
              </div>
            </div>
          </div>
        </Transition>
      </div>
    );
  };

  const renderFieldOptions = (field, sectionId, index) => {
    return (
      <div className="border rounded p-2 ml-2 mt-2">
        {field.options?.map((option, optionIndex) => {
          return (
            <EditableRow
              title={option}
              hideMovement
              onTextUpdate={(text) => {
                const updatedForm = { ...form };
                if (!field.options) {
                  field.options = [];
                }
                field.options[optionIndex] = text;
                field.options.sort();
                updatedForm.fields[sectionId][index] = field;
                resetCurrentEdits(updatedForm);
              }}
              onDeleteClick={() => {
                if (window.confirm("Are you sure want to delete this?")) {
                  const updatedForm = { ...form };
                  if (!field.options) {
                    field.options = [];
                  }
                  field.options.splice(optionIndex, 1);
                  field.options.sort();
                  updatedForm.fields[sectionId][index] = field;
                  resetCurrentEdits(updatedForm);
                }
              }}
            />
          );
        })}
        <TextInputWithButton
          placeholder="Add an option..."
          onButtonClick={(text) => {
            const updatedForm = { ...form };
            if (!field.options) {
              field.options = [];
            }
            field.options.push(text);
            field.options.sort();
            updatedForm.fields[sectionId][index] = field;
            resetCurrentEdits(updatedForm);
          }}
          icon={
            <>
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </>
          }
        />
      </div>
    );
  };

  const renderFormItems = () => {
    return form.fields[selectedSectionId]?.map((field, i) => {
      return (
        <div className="flex flex-row items-start justify-between bg-white shadow-md my-4 px-4 pt-2 pb-4 rounded">
          <Badge
            className="mr-8 mt-2 w-1/3"
            text={formFieldTypes[field.type]}
            bgColor={"bg-gray-200"}
            fgColor={"text-gray-800"}
          />

          <div className="w-1/3 border rounded ml-2 p-2">
            <div className="text-sm">Options</div>
            <Checkbox
              label={"Required?"}
              checked={field.required}
              onChange={(val) => {
                const updatedForm = { ...form };
                updatedForm.fields[selectedSectionId][i].required = val;
                resetCurrentEdits(updatedForm);
              }}
            />
          </div>
          <div className="w-full flex flex-col justify-start items-end">
            <EditableRow
              title={field.label}
              onTextUpdate={(text) => {
                const updatedForm = { ...form };
                updatedForm.fields[selectedSectionId][i].label = text;
                resetCurrentEdits(updatedForm);
              }}
              onUpClick={() => {
                const updatedForm = { ...form };
                arrayMove(updatedForm.fields[selectedSectionId], i, i - 1);
                resetCurrentEdits(updatedForm);
              }}
              onDownClick={() => {
                const updatedForm = { ...form };
                arrayMove(updatedForm.fields[selectedSectionId], i, i + 1);
                resetCurrentEdits(updatedForm);
              }}
              onDeleteClick={() => {
                if (window.confirm("Are you sure want to delete this?")) {
                  const updatedForm = { ...form };
                  updatedForm.fields[selectedSectionId].splice(i, 1);
                  resetCurrentEdits(updatedForm);
                }
              }}
            />
            {field.type === "dropdown"
              ? renderFieldOptions(field, selectedSectionId, i)
              : null}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col py-4 items-center bg-off-white min-h-screen">
      {!form ? (
        <div className="mt-8 mb-4">
          <ScaleLoader size={150} color={SPINNER_COLOR} loading={true} />
        </div>
      ) : (
        <div className="w-full flex flex-row items-start relative">
          <div className="px-4 py-2 rounded border-gray-300 border bg-white mr-2 mt-16">
            <div className="w-60">Sections</div>
            <div>{renderSections()}</div>
            <Button
              onClick={() => {
                const updatedForm = { ...form };
                updatedForm.sections.push({
                  id: uuidv4(),
                  title: "New section",
                });
                resetCurrentEdits(updatedForm);
              }}
              flat
              title="Add Section"
              full
            />
          </div>
          <div className="w-full px-4 py-2 rounded border-gray-300">
            <div className="text-right">
              <AddItemButton />
            </div>
            {renderFormItems()}
          </div>
          {loading ? (
            <div className="absolute cursor-wait w-full h-full" />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default withAdminWrapper(CustomizeForm, "Customize Form");
