import axios from "axios";
import config from "./config";

const subdomain = window.location.host.split(".")[1]
  ? window.location.host.split(".")[0]
  : "proguard";

const domain = window.location.hostname;

const setAuthToken = (token) => {
  localStorage.setItem("token", token);
};

const clearAuthToken = () => {
  localStorage.removeItem("token");
};

const axiosInstance = axios.create({
  baseURL: config.base_url,
  responseType: "json",
});

axiosInstance.interceptors.request.use((req) => {
  req.headers.Authorization = localStorage.getItem("token");
  req.headers.Subdomain = subdomain;
  req.headers.Domain = domain;
  return req;
});

// for multiple requests
let failedRequests = [];
let isRefreshing = false;
let firebase = null;

const processQueue = (error, token = null) => {
  failedRequests.forEach((prom, i) => {
    console.log("processing queue: " + i);
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedRequests = [];
};

const setFirebase = (fb) => {
  firebase = fb;
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (!firebase) {
      return Promise.reject(error);
    }

    if (
      error &&
      error.response.status === 401 &&
      error.config &&
      !error.config.__isRetryRequest
    ) {
      const originalRequest = error.config;
      originalRequest.__isRetryRequest = true;

      if (isRefreshing) {
        console.log("pushing item to failedRequests");
        return new Promise(function (resolve, reject) {
          failedRequests.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = token;
            console.log("processing originalRequest: ", originalRequest.url);
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      return new Promise(async (resolve, reject) => {
        try {
          const token = await new Promise(async (res, rej) => {
            if (firebase.auth && firebase.auth.currentUser) {
              res(await firebase.auth.currentUser.getIdToken(true));
            } else {
              firebase.auth.onAuthStateChanged(async (user) => {
                if (user) {
                  res(await firebase.auth.currentUser.getIdToken(true));
                } else {
                  rej(new Error());
                }
              });
            }
          });
          setAuthToken(token);
          processQueue(null, token);
          resolve(axiosInstance(originalRequest));
        } catch (err) {
          console.log(err);
          processQueue(err, null);
          reject(err);
        } finally {
          isRefreshing = false;
        }
      });
    } else {
      console.log("1", error);
    }

    return Promise.reject(error);
  }
);

export { setAuthToken, clearAuthToken, setFirebase, subdomain };
export default axiosInstance;
