import React from "react";
import Navigation from "./Navigation";
import withWhitelistAuthorization from "../Session/withWhitelistAuthorization";

export default function (WrappedComponent, title) {
  // ...and returns another component...
  return withWhitelistAuthorization(
    class extends React.Component {
      renderMobileAlert() {
        return (
          <div className="rounded-md bg-yellow-50 p-4 top-0 left-0 right-0 md:hidden">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm leading-5 font-medium text-yellow-800">
                  For the best experience, please access the Admin Dashboard
                  from a computer.
                </h3>
              </div>
            </div>
          </div>
        );
      }

      render() {
        console.log("user: ", this.props.user);
        if (!this.props.user.admin) {
          return (
            <div className="h-screen flex overflow-hidden bg-gray-100 justify-center items-center">
              You do not have admin access.
            </div>
          );
        }

        return (
          <div className="flex flex-col">
            {this.renderMobileAlert()}
            <div className="h-screen flex overflow-hidden bg-gray-100">
              <Navigation>
                <main
                  className="flex-1 relative z-0 overflow-y-auto py-6 focus:outline-none"
                  tabIndex="0"
                >
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">
                      {title}
                    </h1>
                  </div>
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <div className="py-4">
                      <WrappedComponent {...this.props} />
                    </div>
                  </div>
                </main>
              </Navigation>
            </div>
          </div>
        );
      }
    },
    false
  );
}
