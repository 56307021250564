import cx from "classnames";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import api from "../../utils/api";
import { SPINNER_COLOR, usePrimaryColor } from "../../utils/utils";
import { Context } from "../Cache";
import * as ACTIONS from "../Cache/actions";
import { withWhitelistAuthorization } from "../Session";
import { withTabBar } from "../UI/TabBar";

const OnDuty = (props) => {
  const [state, dispatch] = useContext(Context);
  const primaryColor = usePrimaryColor();
  const [loading, setLoading] = useState(false);

  const loadOnDuty = async () => {
    setLoading(true);
    try {
      const result = await api.get("/widgets/onDuty");
      console.log(result);
      dispatch({
        type: ACTIONS.ACTION_ON_DUTY_SET,
        payload: result.data.onDutyItems,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadOnDuty();
  }, []);

  const renderWidgetValue = (onDutyItem) => {
    return (
      <>
        <div className="uppercase text-xs font-semibold tracking-wide">
          {onDutyItem.title}
        </div>
        {onDutyItem.members.map((memberId) => {
          const member = state.memberList.find(
            (member) => member.id === memberId
          );
          return (
            <div
              key={memberId}
              className="flex flex-row items-center justify-between text-sm"
            >
              <span className="block">
                <span className="inline-block mr-2">
                  {`${member.firstName} ${member.lastName}`}
                </span>
                {member.callSign ? (
                  <span className="inline-block">{` (${member.callSign})`}</span>
                ) : null}
              </span>
              <a
                className={cx(
                  "underline px-2 py-2 font-mono block mr-1",
                  `text-${primaryColor}-500`
                )}
                href={`tel:${member.phoneNumber}`}
              >
                {member.phoneNumber}
              </a>
            </div>
          );
        })}
      </>
    );
  };

  const renderItemList = () => {
    if (!state.onDutyItems) {
      return null;
    }
    return (
      <>
        {state.onDutyItems
          .sort((a, b) => a.order - b.order)
          .map((onDutyItem) => {
            return (
              <div className="px-4 py-2 rounded overflow-hidden border-gray-300 border bg-white max-w-lg w-full mb-6">
                <div className="uppercase font-light text-center text-sm mb-2">
                  {`${format(new Date(onDutyItem.timeStart), "MMM dd ha")}
              - ${format(new Date(onDutyItem.timeEnd), "MMM dd ha")}
              `}
                </div>
                {renderWidgetValue(onDutyItem)}
              </div>
            );
          })}
        <div className="h-16" />
      </>
    );
  };

  return (
    <div className="flex flex-col sm:px-8 py-4 items-center bg-off-white min-h-screen">
      <div className="text-center uppercase font-semibold tracking-wide mb-2">
        On duty
      </div>
      {(loading && !state.onDutyItems) || !state.memberList ? (
        <div className="mt-8">
          <ScaleLoader size={150} color={SPINNER_COLOR} loading={true} />
        </div>
      ) : (
        renderItemList()
      )}
    </div>
  );
};

export default withWhitelistAuthorization(withTabBar(OnDuty), true);
