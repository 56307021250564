import "chartjs-plugin-datalabels";
import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as ROUTES from "../../constants/routes";
import api from "../../utils/api";
import {
  SPINNER_COLOR,
  useFetchFieldValueForAnalytics,
  usePrimaryColor,
} from "../../utils/utils";
import { Context } from "../Cache";
import * as ACTIONS from "../Cache/actions";
import Button from "../UI/Button";
import IconButton from "../UI/IconButton";
import AnalyticsForm from "./AnalyticsForm";
import withAdminWrapper from "./withAdminWrapper";
var qs = require("qs");
const colors = [
  "#003f5c",
  "#2f4b7c",
  "#665191",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
];

const Analytics = (props) => {
  const [state, dispatch] = useContext(Context);
  const primaryColor = usePrimaryColor();
  const [loading, setLoading] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [incidents, setIncidents] = useState([]);
  const [itemLoading, setItemLoading] = useState(null);
  const fetchFieldValue = useFetchFieldValueForAnalytics();

  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  const defaultStartTime = new Date();
  defaultStartTime.setHours(0, 0, 0, 0);
  defaultStartTime.setDate(defaultStartTime.getDate() - 7);

  const defaultEndTime = new Date();
  defaultEndTime.setHours(23, 59, 59, 999);

  const apiParams = {
    startDate: params.startDate || defaultStartTime.getTime(),
    endDate: params.endDate || defaultEndTime.getTime(),
  };
  const apiParamsString = qs.stringify(apiParams);

  useEffect(() => {
    loadIncidents(apiParamsString);
  }, [apiParamsString]);

  useEffect(() => {
    loadCharts();
  }, []);

  const loadIncidents = async (apiParamsString) => {
    setLoading(true);
    try {
      const result = await api.get("/admin/incidents?" + apiParamsString);
      setIncidents(
        result.data.incidents.filter((incident) => {
          if (!apiParams.filterKey) {
            return true;
          }
          return incident[apiParams.filterKey] === apiParams.filterValue;
        }) || []
      );
      console.log(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const loadCharts = async () => {
    setLoading(true);
    try {
      const result = await api.get("/admin/charts/get");
      console.log(result);
      dispatch({
        type: ACTIONS.ACTION_CHARTS_SET,
        payload: result.data.charts,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteItem = async (id) => {
    try {
      setItemLoading(id);
      await api.post("/admin/charts/delete", {
        id,
      });
      loadCharts();
    } catch (e) {
      console.log(e);
    } finally {
      setItemLoading(null);
    }
  };

  const renderChart = (chart) => {
    const data = {};

    for (var i = 0; i < incidents.length; i++) {
      const incident = incidents[i];
      console.log(
        `checking for ${chart.fieldKey}, got: `,
        incident[chart.fieldKey]
      );
      if (incident[chart.fieldKey] === undefined) {
        continue;
      }
      const name = fetchFieldValue(
        state.config.form.fieldsMap[chart.fieldKey],
        incident
      );

      if (Array.isArray(name)) {
        for (let j = 0; j < name.length; j++) {
          const tempName = name[j];
          if (!data[tempName]) {
            data[tempName] = 0;
          }
          data[tempName]++;
        }
      } else {
        if (!data[name]) {
          data[name] = 0;
        }
        data[name]++;
      }
    }

    let dataArray = Object.keys(data).map((key) => {
      return { label: key, value: data[key] };
    });

    console.log("before sort: ", dataArray);
    dataArray = dataArray.sort((a, b) => {
      return b.value - a.value;
    });
    console.log("after sort: ", dataArray);

    const chartColors = dataArray.map(
      (obj, index) => colors[index % colors.length]
    );

    return (
      <>
        <div className="uppercase text-xs font-semibold tracking-wide mb-4">
          {chart.title}
        </div>
        <Bar
          options={{
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 30,
                bottom: 0,
              },
            },
            plugins: {
              datalabels: {
                align: "end",
                anchor: "end",
                color: function (context) {
                  return context.dataset.backgroundColor;
                },
                font: function (context) {
                  var w = context.chart.width;
                  return {
                    size: w < 512 ? 12 : 14,
                  };
                },
              },
            },
            responsive: true,
            legend: { display: false },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                      if (value % 1 === 0) {
                        return value;
                      }
                    },
                  },
                },
              ],
            },
          }}
          data={{
            labels: dataArray.map((a) => a.label),
            datasets: [
              {
                data: dataArray.map((a) => a.value),
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
              },
            ],
          }}
        />
      </>
    );
  };

  return (
    <div className="flex flex-col items-center bg-off-white min-h-screen">
      {loading && !state.charts ? (
        <div className="mt-8 mb-4">
          <ScaleLoader size={150} color={SPINNER_COLOR} loading={true} />
        </div>
      ) : (
        <>
          <div className="w-full flex flex-wrap">
            <label className="w-full md:w-auto">
              <span className="text-gray-700 mb-1">From</span>
              <div className="w-full">
                <DatePicker
                  className="form-input mt-1 block w-full"
                  selected={
                    apiParams.startDate
                      ? new Date(parseInt(apiParams.startDate))
                      : null
                  }
                  onChange={(date) => {
                    let startDate = "";
                    if (date) {
                      date.setHours(0, 0, 0, 0);
                      startDate = date.getTime();
                    }

                    props.history.replace({
                      pathname: ROUTES.ADMIN_ANALYTICS,
                      search: qs.stringify({
                        ...apiParams,
                        startDate,
                      }),
                    });
                  }}
                  dateFormat="MMMM d, yyyy"
                />
              </div>
            </label>

            <label className="w-full md:w-auto md:ml-2">
              <span className="text-gray-700 mb-1">To</span>
              <div className="w-full">
                <DatePicker
                  className="form-input mt-1 block w-full"
                  selected={
                    apiParams.endDate
                      ? new Date(parseInt(apiParams.endDate))
                      : null
                  }
                  onChange={(date) => {
                    let endDate = "";
                    if (date) {
                      date.setHours(23, 59, 59, 999);
                      endDate = date.getTime();
                    }

                    props.history.replace({
                      pathname: ROUTES.ADMIN_ANALYTICS,
                      search: qs.stringify({
                        ...apiParams,
                        endDate,
                      }),
                    });
                  }}
                  dateFormat="MMMM d, yyyy"
                />
              </div>
            </label>
          </div>
          <div className="w-full flex flex-wrap justify-between">
            {state.charts
              ? state.charts
                  .sort((a, b) => a.order - b.order)
                  .map((chart) => {
                    return (
                      <div className="w-6/12 p-2">
                        <div
                          className={cx(
                            "relative px-4 py-2 rounded overflow-hidden border-gray-300 border bg-white",
                            {
                              "opacity-50 cursor-not-allowed":
                                chart.id === itemLoading,
                            }
                          )}
                        >
                          <div className="absolute top-0 right-0 flex flex-row">
                            <IconButton
                              onClick={() => {
                                setEditItem(chart);
                              }}
                              color="text-gray-500"
                              icon={
                                <>
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </>
                              }
                            />
                            <IconButton
                              onClick={() => {
                                deleteItem(chart.id);
                              }}
                              color="text-red-500"
                              icon={
                                <>
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </>
                              }
                            />
                          </div>
                          {renderChart(chart)}
                        </div>
                      </div>
                    );
                  })
              : null}
          </div>
        </>
      )}
      <Button
        className="w-full max-w-lg"
        title="Add item"
        onClick={() => {
          let order = 0;
          if (
            state.onDutyItems &&
            state.onDutyItems.length > 0 &&
            state.onDutyItems[state.onDutyItems.length - 1].order + 1
          ) {
            order = state.onDutyItems[state.onDutyItems.length - 1].order + 1;
          }
          setEditItem({
            order,
            editable: true,
          });
        }}
      />
      <AnalyticsForm
        editItem={editItem}
        show={editItem != null}
        hide={() => {
          setEditItem(null);
        }}
        itemAdded={() => {
          setEditItem(null);
          loadCharts();
        }}
      />
    </div>
  );
};

export default withAdminWrapper(Analytics, "Analytics");
