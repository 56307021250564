import React, { useState, useContext } from "react";
import SelectOptions from "./SelectOptions";
import { sentenceCap } from "../../utils/utils";
import { getPermissionOptionData } from "../../utils/permissions";
import { Context } from "../Cache";
import cx from "classnames";

const PermissionsSelector = (props) => {
  const [state, dispatch] = useContext(Context);

  const optionData = getPermissionOptionData(state);
  console.log("optionData: ", optionData);
  return (
    <label className={cx("block", props.className)}>
      <span className="text-gray-700">
        {sentenceCap(props.type)} permissions
      </span>
      <div className="w-full mt-1 flex flex-row">
        <SelectOptions
          className="w-full"
          value={props.permissions[props.type]}
          handleChange={(value) => {
            console.log("value: ", value);
            props.updatePermissions(value);
          }}
          optionLabels={optionData.titles}
          options={optionData.keys}
        />
      </div>
    </label>
  );
};
export default PermissionsSelector;
