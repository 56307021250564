import React, { useContext } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { Context } from "../Cache";

const Navigation = (props) => {
  const [state, dispatch] = useContext(Context);

  return (
    <header className="fixed w-full top-0 z-50 bg-white flex flex-col px-4 py-1 shadow-md">
      <div className="flex flex-row items-center justify-between p-0 w-full">
        <Link to={ROUTES.INCIDENTS_LIST}>
          <div className="font-bold text-base tracking-wide px-2 flex flex-row items-center cursor-pointer">
            <img
              className="object-contain h-10"
              src={state.config && state.config.appearance.logo}
            />
            <div className="ml-4">
              {state.config && state.config.appearance.name}
            </div>
          </div>
        </Link>
      </div>
    </header>
  );
};
export default withRouter(withFirebase(Navigation));
